@import url("/font-awesome/css/all.min.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Source+Sans+Pro:ital,wght@0,400;0,600;1,700&display=swap");
@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Medium.otf");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Book.otf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'tfv-icon';
  src: url("/fonts/tfv-icon/tfv-icon.eot?ysq9j9");
  src: url("/fonts/tfv-icon/tfv-icon.eot?ysq9j9#iefix") format("embedded-opentype"), url("/fonts/tfv-icon/tfv-icon.ttf?ysq9j9") format("truetype"), url("/fonts/tfv-icon/tfv-icon.woff?ysq9j9") format("woff"), url("/fonts/tfv-icon/tfv-icon.svg?ysq9j9#tfv-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icon-']:before,
[class*=' icon-']:before,
[class^='icon-']:after,
[class*=' icon-']:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tfv-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-filter:before {
  content: '\e900';
  color: #c5c5c5; }

.icon-your-compensation:before {
  content: '\e901';
  color: #c5c5c5; }

.icon-warning:before {
  content: '\e902';
  color: #c0c2c3; }

.icon-waiting-to-be-submitted:before {
  content: '\e903';
  color: #fff; }

.icon-trophy:before {
  content: '\e904';
  color: #bdbdbd; }

.icon-timer:before {
  content: '\e905';
  color: #c5c5c5; }

.icon-the-envelope:before {
  content: '\e906';
  color: #c5c5c5; }

.icon-task:before {
  content: '\e907';
  color: #c5c5c5; }

.icon-submit-time:before {
  content: '\e908';
  color: #c5c5c5; }

.icon-small:before {
  content: '\e909';
  color: #bdbdbd; }

.icon-share:before {
  content: '\e90a';
  color: #c5c5c5; }

.icon-search:before {
  content: '\e90b'; }

.icon-schedule:before {
  content: '\e90c';
  color: #bdbdbd; }

.icon-report-quality:before {
  content: '\e90d';
  color: #c5c5c5; }

.icon-question:before {
  content: '\e90e';
  color: #c0c2c3; }

.icon-profile:before {
  content: '\e90f';
  color: #c5c5c5; }

.icon-pointer:before {
  content: '\e910';
  color: #c5c5c5; }

.icon-photo:before {
  content: '\e911';
  color: #9e9e9e; }

.icon-news:before {
  content: '\e912';
  color: #c5c5c5; }

.icon-lock:before {
  content: '\e913';
  color: #c0c2c3; }

.icon-interprete-now:before {
  content: '\e914';
  color: #c5c5c5; }

.icon-interpret-now-2:before {
  content: '\e915';
  color: #fff; }

.icon-user:before {
  content: '\e916';
  color: #bdbdbd; }

.icon-hourglass:before {
  content: '\e917';
  color: #c9c9c9; }

.icon-home:before {
  content: '\e918';
  color: #c5c5c5; }

.icon-handset:before {
  content: '\e919';
  color: #c5c5c5; }

.icon-handset-2:before {
  content: '\e91a';
  color: #c5c5c5; }

.icon-flag:before {
  content: '\e91b';
  color: #bdbdbd; }

.icon-fire:before {
  content: '\e91c';
  color: #fff; }

.icon-drawing-pin:before {
  content: '\e91d';
  color: #bdbdbd; }

.icon-download-to-cloud:before {
  content: '\e91e';
  color: #c0c2c3; }

.icon-dollar:before {
  content: '\e91f';
  color: #fff; }

.icon-dollar-2:before {
  content: '\e920';
  color: #d1d1d1; }

.icon-create-new:before {
  content: '\e921';
  color: #fff; }

.icon-contact:before {
  content: '\e922';
  color: #c5c5c5; }

.icon-contact-2:before {
  content: '\e923';
  color: #c5c5c5; }

.icon-comment:before {
  content: '\e924';
  color: #c0c2c3; }

.icon-comment-2:before {
  content: '\e925';
  color: #fff; }

.icon-close:before {
  content: '\e926';
  color: #313131; }

.icon-next:after {
  content: '\e931';
  color: #313131; }

.icon-previous:before {
  content: '\e932';
  color: #313131; }

.icon-certificate:before {
  content: '\e927';
  color: #bdbdbd; }

.icon-camera:before {
  content: '\e928';
  color: #014a8e; }

.icon-calendar:before {
  content: '\e929';
  color: #a5a4a4; }

.icon-browse-assignments:before {
  content: '\e92a';
  color: #c5c5c5; }

.icon-book:before {
  content: '\e92b';
  color: #c5c5c5; }

.icon-book-2:before {
  content: '\e92c';
  color: #c0c2c3; }

.icon-bell:before {
  content: '\e92d';
  color: #c5c5c5; }

.icon-available-request:before {
  content: '\e92e';
  color: #c5c5c5; }

.icon-available-request-2:before {
  content: '\e92f';
  color: #fff; }

.icon-attach:before {
  content: '\e930';
  color: #9e9e9e; }

.icon-arrow-more:before {
  content: '\e931';
  color: #c5c5c5; }

.icon-arrow-more-2:before {
  content: '\e932';
  color: #c5c5c5; }

.icon-arrow-expand:before {
  content: '\e933'; }

.icon-arrow-down:after {
  content: '\e933'; }

.icon-arrow-top:after {
  content: '\e931';
  display: inline-block;
  transform: rotate(-90deg); }

.icon-arrow-back:before {
  content: '\e934'; }

.icon-settings:before {
  content: '\e935';
  color: #c5c5c5; }

.icon-envelope:before {
  content: '\e936';
  color: #c5c5c5; }

.icon-log-out:before {
  content: '\e937';
  color: #014a8e; }

.icon-more:before {
  content: '\e938';
  color: #c5c5c5; }

.icon-check:before {
  content: '\e939';
  color: #c5c5c5; }

.icon-doc:before {
  content: '\e93a';
  color: #c5c5c5; }

.icon-pdf:before {
  content: '\e93b';
  color: #c5c5c5; }

.icon-download-file:before {
  content: '\e93c';
  color: #c5c5c5; }

.icon-upload-to-cloud:before {
  content: '\e93d';
  color: #c5c5c5; }

.icon-single:before {
  content: '\e93e';
  color: #c5c5c5; }

.icon-multi:before {
  content: '\e93f';
  color: #c5c5c5; }

.icon-image-file:before {
  content: '\e940';
  color: #c5c5c5; }

.icon-mobile-phone:before {
  content: '\e941';
  color: #c5c5c5; }

.icon-customer-order-number:before {
  content: '\e942';
  color: #c5c5c5; }

.icon-edit:before {
  content: '\2639';
  color: '#000'; }

.icon-task-list:before {
  content: '\2630';
  color: #c5c5c5; }

.timesheet {
  padding: 0 32px 24px 32px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .timesheet {
      max-height: calc(100vh - 100px);
      overflow-y: auto; } }
  .timesheet__modal .tv-modal__holder {
    min-width: 672px;
    letter-spacing: 0.1px; }
    .timesheet__modal .tv-modal__holder h6 {
      letter-spacing: 0.1px; }
    .timesheet__modal .tv-modal__holder .timesheet__travel_time:nth-child(2) {
      margin-left: 32px; }
    .timesheet__modal .tv-modal__holder .timesheet-button_cancel:focus-visible, .timesheet__modal .tv-modal__holder .timesheet-button_submit:focus-visible {
      outline: 2px solid #be5a08;
      outline-offset: 2px; }
  .timesheet-button_cancel {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;
    height: 48px;
    font: 400 16px/20px "Source Sans Pro", "Source Sans Pro";
    padding: 10px;
    background: #fff;
    margin: 20px 0 2px; }
  .timesheet-button_submit {
    border: none;
    font: 400 16px/20px "Source Sans Pro", "Source Sans Pro";
    border-radius: 4px;
    width: 100%;
    height: 48px;
    padding: 10px;
    color: #fff;
    background-color: #014a8e;
    margin: 20px 0 2px; }
  .timesheet__divider {
    border-top: 1px solid #e0e0e0;
    margin-top: 8px; }
  .timesheet__attached-file__container {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    font-size: 14px;
    margin-top: 16px;
    margin-right: 32px; }
    .timesheet__attached-file__container i {
      cursor: pointer; }
  .timesheet__attached-file__amount {
    min-width: 50px; }
  .timesheet__attached-file__content {
    display: flex; }
  .timesheet__attached-file__name {
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .timesheet input:disabled {
    background: #fff; }
  .timesheet select:disabled {
    color: #a9a9a9; }
  .timesheet__header {
    font-family: "Source Sans Pro", "Source Sans Pro"; }
    .timesheet__header-title {
      background-color: #be5a08;
      border-radius: 4px 4px 0 0;
      color: #fff;
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 2.1875rem;
      padding: 16px 24px; }
    .timesheet__header-content {
      padding: 24px 0;
      font-weight: 300;
      letter-spacing: -0.5px;
      border-bottom: 1px solid #e0e0e0; }
      .timesheet__header-content .redirect-checkbox label {
        padding-left: 15px;
        font: 400 16px/20px "Source Sans Pro", "Source Sans Pro" !important; }
      .timesheet__header-content .tv-single-assign-form__control {
        height: 48px;
        margin-top: 8px;
        margin-bottom: 16px; }
        .timesheet__header-content .tv-single-assign-form__control .k-input {
          padding: 16px 14px;
          font: 400 16px/20px "Source Sans Pro", "Source Sans Pro" !important;
          height: 48px;
          align-content: center;
          display: flex; }
      .timesheet__header-content textarea.tv-single-assign-form__control {
        margin-top: 8px;
        height: 70px !important; }
      .timesheet__header-content .tv-rate-submit-btn {
        width: 100px;
        font: 400 16px/20px "Source Sans Pro", "Source Sans Pro" !important;
        color: #fff;
        background-color: #014a8e;
        padding: 8px !important;
        height: 40px; }
      .timesheet__header-content .rating-label {
        font: 400 16px/20px "Source Sans Pro", "Source Sans Pro" !important;
        margin-bottom: 8px;
        margin-top: 16px; }
      .timesheet__header-content button {
        color: #000;
        background: #c4c4c4;
        border: #c4c4c4;
        font-weight: 500;
        font-size: 15px !important; }
      .timesheet__header-content-id {
        color: #000;
        font: 400 18px/23px "Source Sans Pro", "Source Sans Pro";
        margin: 0px 10px 18px 0;
        float: left; }
      .timesheet__header-content-type {
        color: #252525;
        font: 400 14px/18px "Source Sans Pro", "Source Sans Pro";
        margin: 0;
        text-align: left; }
      .timesheet__header-content-date {
        color: #a9a9a9;
        font: 0.875rem/1.25rem "Open Sans", "Open Sans";
        letter-spacing: 0.3px;
        margin: 10px 0 0 0;
        text-align: left; }
        .timesheet__header-content-date i {
          margin-right: 15px; }
    .timesheet__header-rating {
      margin-top: -3px;
      background: white;
      border-radius: 12px; }
      .timesheet__header-rating.rating > label {
        padding-right: 2px !important; }
  .timesheet__travel_time {
    padding: 16px 0px 0px;
    font-family: "Source Sans Pro", "Source Sans Pro";
    margin-top: 0px;
    text-align: left;
    border-radius: 4px;
    width: 100%; }
    .timesheet__travel_time .read-only-data {
      margin: 0;
      flex-direction: column;
      display: flex; }
      .timesheet__travel_time .read-only-data > div {
        align-items: center;
        margin-bottom: 16px; }
      .timesheet__travel_time .read-only-data label {
        font: 300 14px/18px "Source Sans Pro", "Source Sans Pro" !important;
        margin-bottom: 0px !important; }
      .timesheet__travel_time .read-only-data span {
        font: 300 14px/18px "Source Sans Pro", "Source Sans Pro" !important; }
    .timesheet__travel_time .tv-form__date-field {
      padding: 11px 8px !important; }
      .timesheet__travel_time .tv-form__date-field:after {
        top: 13px !important;
        right: 11px !important; }
    .timesheet__travel_time.travel-home {
      background-color: #f4f4f4;
      padding: 20px 32px;
      border: 1px solid #757474; }
    .timesheet__travel_time__box {
      padding: 10px 0; }
      .timesheet__travel_time__box .tv-col-6 {
        padding-top: 20px; }
      .timesheet__travel_time__box__content {
        width: 100%;
        border: 1px solid #757474;
        border-radius: 5px;
        padding: 20px; }
        .timesheet__travel_time__box__content-file-name {
          padding-top: 12px;
          color: #be5a08;
          font-size: 14px;
          font-size: 0.875rem;
          line-height: 1.125rem;
          padding: 10px 0; }
          .timesheet__travel_time__box__content-file-name span {
            padding-left: 10px; }
        .timesheet__travel_time__box__content .view-data {
          flex-grow: 1; }
        .timesheet__travel_time__box__content-remove {
          color: #014a8e;
          font-size: 14px;
          font-size: 0.875rem;
          display: flex;
          min-width: 90px;
          position: relative;
          cursor: pointer; }
          .timesheet__travel_time__box__content-remove i {
            margin: auto;
            cursor: pointer;
            position: absolute;
            background-color: #014a8e;
            width: 16px;
            border-radius: 50%;
            height: 16px;
            top: 50%;
            transform: translate(50%, -50%); }
            .timesheet__travel_time__box__content-remove i::before {
              color: #fff;
              padding: 4px;
              display: block;
              font-size: 8px;
              font-size: 0.5rem; }
          .timesheet__travel_time__box__content-remove .title-close {
            padding-left: 20px;
            margin: auto; }
    .timesheet__travel_time-rating-phone {
      margin-left: auto; }
      .timesheet__travel_time-rating-phone .timesheet__rating-text {
        margin: auto;
        padding-right: 10px; }
      .timesheet__travel_time-rating-phone .rating {
        border: none;
        display: inline-block; }
        .timesheet__travel_time-rating-phone .rating > input {
          display: none; }
        .timesheet__travel_time-rating-phone .rating > label {
          padding-right: 2px; }
          .timesheet__travel_time-rating-phone .rating > label:before {
            font-size: 24px;
            font-size: 1.5rem; }
    .timesheet__travel_time-title {
      font: 1.125rem/1.75rem "Source Sans Pro", "Source Sans Pro";
      letter-spacing: -0.2px;
      font-weight: 300;
      color: #000;
      line-height: 2.2; }
    .timesheet__travel_time-content {
      margin-top: 0; }
      .timesheet__travel_time-content .tv-form__date-field {
        padding: 11px 8px !important; }
        .timesheet__travel_time-content .tv-form__date-field:after {
          top: 13px !important;
          right: 11px !important; }
      .timesheet__travel_time-content .tv-single-assign-form__input-arrow:after {
        display: none; }
      .timesheet__travel_time-content .button-plus {
        position: relative;
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 1.75rem;
        padding: 11px 28px 11px 40px; }
        .timesheet__travel_time-content .button-plus i {
          position: absolute;
          left: 20px;
          margin-top: 3px; }
        .timesheet__travel_time-content .button-plus::after {
          content: '+';
          position: absolute;
          right: 10px;
          color: #014a8e;
          margin-top: 3px;
          font-size: 20px;
          font-weight: 900;
          line-height: 25px; }
      .timesheet__travel_time-content .button-minus {
        position: relative;
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 1.75rem;
        padding: 11px 28px 11px 40px; }
        .timesheet__travel_time-content .button-minus i {
          position: absolute;
          left: 20px;
          margin-top: 3px; }
        .timesheet__travel_time-content .button-minus::after {
          content: '-';
          position: absolute;
          right: 10px;
          color: #014a8e;
          margin-top: 3px;
          font-size: 20px;
          font-weight: 900;
          line-height: 25px; }
    .timesheet__travel_time-label {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: #313131;
      padding-bottom: 5px;
      margin: 0 0 0 0;
      font-size: 15px;
      font-weight: 400;
      font-family: "Source Sans Pro", "Source Sans Pro"; }
      .timesheet__travel_time-label::before {
        margin-right: 10px; }
    .timesheet__travel_time-input {
      height: 52px;
      padding: 16px 20px; }
    .timesheet__travel_time .timesheet_time input {
      width: 100%;
      height: 40px;
      border: 1px solid #bdbdbd !important;
      padding: 10px;
      color: black; }
    .timesheet__travel_time .timesheet_time .rc-time-picker-input {
      border: 1px solid #757474; }
    .timesheet__travel_time .timesheet_time i {
      margin-top: 22px; }
    .timesheet__travel_time .tv-interperet-now__start-time-wrapper {
      padding-top: 5px;
      margin: 0; }
    .timesheet__travel_time .tv-row > [class^='tv-col-'] {
      padding-right: 12px;
      padding-left: 12px; }
  .timesheet__checkbox {
    font-size: 14px;
    font-size: 0.875rem;
    padding-top: 20px; }
    .timesheet__checkbox .tv-form__checkbox-checkmark {
      background-color: #fff;
      border: 1px solid #be5a08; }
  .timesheet__rating {
    min-height: 150px;
    background-color: #f7f7f7;
    text-align: center;
    margin: 48px 0 36px;
    padding: 15px 32px 5px; }
    .timesheet__rating-text {
      color: #a9a9a9;
      font-family: "Open Sans", "Open Sans";
      font-size: 14px;
      font-size: 0.875rem;
      letter-spacing: 0.3px;
      line-height: 1.25rem; }
  .timesheet__submit {
    padding: 10px 0 40px;
    width: 200px;
    margin: 0 auto; }
    .timesheet__submit button {
      padding: 15px 0; }
  .timesheet .tv-single-assign-form__input-arrow {
    position: relative;
    margin-top: 5px; }
  .timesheet .tv-single-assign-form__textfield {
    padding: 16px 20px; }
    .timesheet .tv-single-assign-form__textfield:disabled {
      color: #a9a9a9; }
  .timesheet .tv-single-assign-form__label {
    margin: 0 0 0 0;
    font-size: 15px;
    font-weight: 400; }
    .timesheet .tv-single-assign-form__label.icon-calendar > div {
      display: inline-block;
      padding-left: 10px; }
    .timesheet .tv-single-assign-form__label.icon-submit-time > div {
      display: inline-block;
      padding-left: 10px; }
  .timesheet .tv-single-assign-form__input {
    margin-left: 0; }
  .timesheet .tv-col-2 .tv-form__date-field {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .timesheet .pdt0 {
    padding-top: 0; }
  .timesheet .mgt0 {
    margin-top: 0; }
  .timesheet .read-only {
    user-select: none;
    cursor: not-allowed;
    pointer-events: none; }
    .timesheet .read-only .icon-arrow-expand {
      display: none; }
  .timesheet__signature span {
    display: block; }
  .timesheet__signature img {
    max-height: 150px; }

.tv-single-assign-form__control {
  color: #313131; }

.timesheet_modal .tv-modal__holder {
  text-align: center;
  padding: 20px 90px 30px; }
  .timesheet_modal .tv-modal__holder .image-upload > input {
    display: none; }
  .timesheet_modal .tv-modal__holder .image-upload .icon-upload-to-cloud::before {
    color: #be5a08;
    font-size: 22px;
    font-size: 1.375rem; }
  .timesheet_modal .tv-modal__holder .image-upload__title {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #be5a08;
    margin: 5px; }
  .timesheet_modal .tv-modal__holder .image-preview {
    width: 120px;
    border: 1px solid #979797;
    border-radius: 2px;
    margin: 20px auto 0;
    position: relative; }
    .timesheet_modal .tv-modal__holder .image-preview img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto; }
    .timesheet_modal .tv-modal__holder .image-preview i.icon-close {
      cursor: pointer;
      position: absolute;
      top: -12px;
      right: -12px;
      background-color: #014a8e;
      width: 24px;
      border-radius: 50%;
      height: 24px; }
      .timesheet_modal .tv-modal__holder .image-preview i.icon-close::before {
        color: white;
        font-size: 12px;
        font-size: 0.75rem; }
  .timesheet_modal .tv-modal__holder .icon-upload-to-cloud {
    cursor: pointer;
    border: 1px solid #be5a08;
    border-radius: 50%;
    display: inline-block;
    padding-top: 5px;
    margin-top: 20px;
    width: 40px;
    height: 40px; }
    .timesheet_modal .tv-modal__holder .icon-upload-to-cloud [class*='path']::before {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'tfv-icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 24px;
      font-size: 1.5rem; }
  .timesheet_modal .tv-modal__holder .path1::before {
    color: #be5a08; }

.timesheet_modal__header {
  color: #313131;
  font: 1.125rem/1.75rem "Source Sans Pro", "Source Sans Pro";
  font-weight: 300;
  letter-spacing: -0.2px; }

.input-group {
  min-width: 275px;
  padding: 28px 0 24px; }
  .input-group.view-data {
    padding: 0;
    min-width: 0; }
  .input-group__label {
    margin-right: -1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 16px;
    margin-bottom: 0;
    font-size: 1rem;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #313131;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #e8e8e8;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group__value {
    min-width: 100px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    padding: 8px 16px;
    color: #757474;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .input-group__textfield {
    border: 1px solid #e8e8e8;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    padding: 8px 16px;
    color: #014a8e; }

@media (max-width: 768px) {
  .timesheet__modal .tv-modal__holder {
    width: calc(100vw);
    min-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    margin-top: 0;
    top: 0;
    border-radius: 0; }
    .timesheet__modal .tv-modal__holder .timesheet__travel_time:nth-child(2) {
      margin-left: 0px; }
    .timesheet__modal .tv-modal__holder button.tv-rate-submit-btn {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px; }
    .timesheet__modal .tv-modal__holder .detailed-rating.timesheet__header-rating {
      width: 100%; }
      .timesheet__modal .tv-modal__holder .detailed-rating.timesheet__header-rating .rating {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-bottom: 20px; }
        .timesheet__modal .tv-modal__holder .detailed-rating.timesheet__header-rating .rating > label:before {
          font-size: 33px !important; }
    .timesheet__modal .tv-modal__holder .footer-wrapper {
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .timesheet__modal .tv-modal__holder .footer-wrapper > .feedback-create-deviation,
      .timesheet__modal .tv-modal__holder .footer-wrapper .tv-rate-submit-btn {
        margin: 0px !important; } }
