@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

label {
  margin: 0;
  padding: 0;
}

.rating-summary-cont {
  padding: 16px 16px 16px 0;
  font-family: $PRIMARY_FONT;
  background-color: #fff;
  .average-rating-count {
    // color: #ff9b4e; 2nd
    color: #db782b;
    // color: #b85e19; 1st
    display: flex;
    font-size: 24px;
    align-items: baseline;
    height: 30px;
    margin: 5px 0 18px;
    & > span {
      margin-right: 14px;
    }
  }
  .description {
    font: 11px/14px $PRIMARY_FONT;
    color: $FONT_LIGHT_GRAY_COLOR;
  }
  h3 {
    font-size: 14px;
  }

  .rating {
    border: none;
    display: block;
    position: relative;

    .average-rating {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      span {
        padding: 9px 0 5px;
        font-size: 13px;
        color: #423c3c;
      }

      label:before {
        margin: 3px;
        @include font-size(20);
        display: inline-block;
        content: "\e912";
        font-family: "tfv-icon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      label {
        color: #ddd;
        float: right;
        padding-right: 9px;
      }

      &.red {
        label.checked {
          color: #f93d3d;
        }
      }

      &.red-yellow {
        label.checked {
          color: #fd974de6;
        }
      }

      &.yellow {
        label.checked {
          color: #ffd700;
        }
      }

      &.yellow-green {
        label.checked {
          color: #c7f055;
        }
      }

      &.green {
        label.checked {
          color: #98f23d;
        }
      }
    }
  }

  .rating-percentage {
    display: block;
    height: 30px;
    background-color: #ddd;
    position: relative;

    span {
      position: absolute;
      height: 30px;
      left: 0;
      top: 0;
      border-radius: 0 9px 9px 0;
      background-color: #98f23d;
    }
  }
}

.stars_area {
  &__container {
    width: 100%;
    justify-content: space-between;
  }
  &__title {
    color: $COMMON_TEXT_COLOR;
    font: 400 16px/20px $PRIMARY_FONT !important;
    margin-bottom: 4px;
  }
  &__pending {
    color: #677adf;
    font-family: $PRIMARY_FONT;
    margin-top: 8px;
    font-size: 13.5px;
    &:hover {
      box-shadow: 0px 0px 2px 0px $BLACK_COLOR;
    }
  }

  @supports (-webkit-background-clip: text) {
    .stars {
      --percent: var(--rating);
      -webkit-text-stroke: 1.5px #ff9b4e;
      display: inline-block;
      font-size: 40px;
      font-family: Times; // make sure ★ appears correctly
      line-height: 1;
      &::before {
        content: "★★★★★";
        font-size: 30px;
        letter-spacing: 3px;
        background: linear-gradient(90deg, #ff9b4e var(--percent), #fff var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .stars {
    color: #ff9b4e;
    font-size: 30px;
    letter-spacing: 3px;
  }
}

@media (max-width: 1024px) {
  .rating-summary-cont {
    // padding: 28px 0px 0px;
    padding: 0;
  }
  .stars_area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__title {
      font-size: 16px;
      font-weight: 500;
      font-family: $PRIMARY_FONT;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
    }
    .average-rating-count {
      & > span {
        display: none;
      }
      margin-bottom: 0;
    }
    .description,
    .stars_area__pending {
      width: 100%;
    }
    .stars {
      -webkit-text-stroke: 1.5px #ff9b4e;
      margin-left: 16px;
      font-size: 25px;
      &::before {
        font-size: 24px;
        letter-spacing: 10px;
      }
    }
  }
}

@media (min-width: 678px) and (max-width: 1024px) {
  .stars_area {
    .flex-md-column {
      flex-direction: row !important;
    }
  }
}
