.#{$prefixClass} {
  &-input {
    position: relative;
    display: inline-block;
    // padding: 4px 7px;
    // height: 28px;
    cursor: text;
    // font-size: 12px;
    // line-height: 1.5;
    // color: #666;
    // background-color: #fff;
    // background-image: none;
    // border: 1px solid #d9d9d9;
    // border-radius: 4px;
    transition: border .2s cubic-bezier(0.645, 0.045, 0.355, 1), background .2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow .2s cubic-bezier(0.645, 0.045, 0.355, 1);

    // DEV NITECO CUSTOMIZE
    height: 52px;
    width: 100px;
    border: 1px solid #C5C5C5;
    border-radius: 4px;
    background-color: #FFFFFF;
    font-family: "PT Serif";
    font-size: 14px;
    line-height: 20px;
    color: #313131;
    padding: 16px 20px;
    outline: none;

    &[disabled] {
      // color: #ccc;
      // background: #f7f7f7;
      // cursor: not-allowed;

      // DEV NITECO CUSTOMIZE
      color: #A9A9A9;
      & ~ .icon-arrow-expand {
        color: #c5c5c5;
      }
    }
  }

  .icon-arrow-expand {
    font-family: "tfv-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";

    font-size: 10px;
    color: #313131;
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: 20px;
    margin-top: 14px;
  }
}
