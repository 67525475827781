@import '../../../Share/styles/variables.scss';

.tv-modal__wrapper {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
}

.tv-display-flex {
  display: flex;
}

.tv-route-leaving-guard-modal {
  z-index: 56 !important;
  text-align: center;
}

.tv-modal__overlay {
  content: '';
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 12;
}

.tv-copy-modal__container {
  width: 700px;
  z-index: 20;
  // animation: zoomIn 0.5s;
  overflow-y: auto;
  position: absolute;
  border-radius: 4px;
  background-color: #fff;
  overflow-x: hidden;
  // top: 60px;
  max-height: 650px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    // border-bottom: 0.5px solid $BORDER_COLOR;
    &-title {
      font: 500 17px/20px $PRIMARY_FONT;
    }
    &-text {
      font: 500 18px/20px $PRIMARY_FONT;
      padding-block: 10px;
    }
  }
  &__divider {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__body {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 18px;
    &-item {
      display: flex;
      padding-block: 8px;
    }
    &__field-name {
      margin-left: 10px;
    }
  }
}

.tv-buttons__type {
  background: transparent;
  border: 0;
  padding: 0;
}

.tv-route-leaving-guard-modal__buttons {
  justify-content: space-between;
  margin-block: 20px 10px;
  margin-inline: 0px !important;
  button {
    width: 300px;
    font-size: 14px;
    height: 45px;
  }
}

.tv-checkbox {
  background-color: #fff !important;
  font: 400 14px/20px $PRIMARY_FONT !important;
  &-label {
    font: 400 14px/20px $PRIMARY_FONT;
  }
}

.tv-workassignment_full-detail {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 240px);
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    max-height: calc(100vh - 40vh);
  }
  @media (max-width: 768px) {
    max-height: calc(100vh - 100px);
  }
}

.additional-services-added {
  margin-right: 10px;
  font: 600 13px/18px $PRIMARY_FONT;
  text-align: justify;
}
