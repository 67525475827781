@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-reportquality {
  text-align: left;

  &__container {
    width: 100%;
  }

  &__wrapper {
    // flex: 1.4 1;
    // justify-content: center;
    // align-items: center;
    // padding-top: 22px;
    background-color: $WHITE_COLOR;
    .tk-left-drawer {
      top: 85px;
    }
    @media (max-width: 768px) {
      .tk-left-drawer {
        top: 0;
      }
    }
    .mt-3 {
      @media (min-width: 768px) and (max-width: 1024px) {
        .d-lg-block {
          display: none !important;
        }
        .col-sm-12 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .d-lg-flex {
          display: none !important;
        }
      }
      .tv-workassignment__list-item {
        @media (min-width: 768px) and (max-width: 1024px) {
          height: 200px;
          border-radius: 4px;
          position: relative;
          border: 1px solid #e0e0e0;
          margin: 16px 0 !important;
          /* padding: 16px; */
        }
      }
      .tv-display-mobile-hide {
        @media (min-width: 768px) and (max-width: 1024px) {
          display: none !important;
        }
      }
      .tv-display-mobile-show {
        @media (min-width: 768px) and (max-width: 1024px) {
          display: block !important;
        }
      }
    }
    .tv-reportquality-drawer-zoom {
      @media (min-width: 768px) and (max-width: 1024px) {
        position: fixed !important;
        top: 0 !important;
        left: 0;
        height: calc(100vh);
        z-index: 50;
        min-height: unset;
        overflow-x: hidden;
        max-width: 100%;
      }
    }
    .tk-report-quality-mobile-filter-wrapper-zoom {
      @media (min-width: 768px) and (max-width: 1024px) {
        position: fixed !important;
        top: 0 !important;
        left: 0;
        height: calc(100vh);
        z-index: 50;
        min-height: unset;
        overflow-x: hidden;
        overflow-y: auto;
        max-width: 100%;
      }
    }

    .tk-assignment-creation__container {
      @media (min-width: 768px) and (max-width: 1024px) {
        left: 0;
        width: 100vw;
        padding: 16px;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100vh !important;
      }
    }
    @media (max-width: 1100px) {
      .tk-left-drawer {
        .tv-drawer_buttons {
          top: 300px;
          width: 100%;
          padding: 16px 16px 16px 0px;
        }
      }
    }
  }

  &_align-unset {
    align-items: unset;
  }

  &__btn {
    &-filter {
      font-family: $PRIMARY_FONT;
      display: block;
      background-color: #c2c7ce;
      border: 1px solid #c2c7ce;
      border-radius: 8px;
      padding: 15px;
      width: 90%;
      margin: 10px;
    }
  }

  &-sendbutton {
    margin: 10px 15px 10px 10px;
    padding: 16px;
    width: 100%;
    border: none;
    background: $DARK_GRAY_COLOR-2;
    border-radius: 4px;
    font-weight: 600;
  }

  &__list {
    &__mobile-fixed-bottom {
      display: none;
      @media (min-width: 768px) and (max-width: 1024px) {
        position: fixed;
        display: flex !important;
        flex-direction: row;
        width: 100% !important;
        padding: 16px;
        background-color: #fff;
        text-align: center;
        display: block;
        left: 0;
        bottom: 0;
        z-index: 40;
        width: 100vw;
      }
    }

    &-item {
      cursor: pointer;
      margin: 0;
      padding: 8px;
      height: 47px;
      font: 400 12px/15px $PRIMARY_FONT;
      z-index: 10;
      border-radius: 0;
      background: #f1f3f4;
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: center;
      &-header {
        font: 400 12px/15px $PRIMARY_FONT;
        border-bottom: 1px solid $BORDER_COLOR;
        padding: 8px;
        margin: 0 0 16px 0;
        margin-bottom: 16px !important;
        display: flex;
        height: 47px;
        justify-content: space-around;
        width: 100%;
        align-items: center;
        z-index: 10;

        .tv-reportquality__item-text:focus-visible {
          outline: 2px solid $OUTLINE_COLOR;
          border-radius: 2px;
          outline-offset: 4px;
        }
      }
      &.selected {
        background: $ORANGE-10 !important;
        .tv-reportquality__item-text {
          color: $WHITE_COLOR !important;
        }
        &:hover {
          // background: $ORANGE-10 !important;
        }
      }
      // &:hover{
      //   background-color: #E8F3FA !important;
      // }
      &-child {
        margin: 0 13px;
        box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2),
          0 10px 18px -12px rgba(58, 68, 131, 0.2);
      }
      &-label {
        display: none;
      }
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:nth-child(even) {
        background: #f1f3f4;
      }
      &:nth-child(odd) {
        background: #fff;
      }
      &-parent:not(.tv-reportquality__list-item-header) {
        &:focus-visible {
          outline: 2px solid $OUTLINE_COLOR;
          border-radius: 2px;
        }
      }
    }
  }

  &__item {
    &-status {
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #74737b;
      color: #fff !important;
      border-radius: 4px;
      padding: 6px 8px;
    }

    &-icon {
      padding: 15px 40px;

      .icon {
        &:before {
          color: black;
        }
      }
    }

    // &-order {
    //   // width: 30%;
    //   margin-right: 10px;
    // }

    // &-name {
    //   // width: 29.1%;
    // }

    // &-category {
    //   // width: 16.7%;
    // }

    // &-datetime {
    //   // width: 27.6%;
    // }

    // &-type {
    //   // width: 12%;
    //   margin-right: 10px;
    // }

    &-more_button {
      outline: none;
      border: none;
      background: none;
      text-transform: uppercase;
      font-size: 14px;

      &:after {
        content: '\e931';
        font-family: 'tfv-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        color: #c5c5c5;
        margin-left: 4px;
        font-size: 10px;
        color: $BLACK_COLOR;
      }
    }

    &-menu {
      // width: 7%;
      align-items: center;
      justify-content: center;

      &-button {
        &:before {
          @include tv-tfv-font;
          font-size: 1.5rem;
        }
      }
    }

    &-label {
      font-size: 0.975rem;
      color: $BLACK_COLOR;
      margin: 0 0 11px 0;
      font-family: $PRIMARY_FONT;
    }

    &-text {
      color: $FONT_LIGHT_GRAY_COLOR;
      font: 12px/16px $PRIMARY_FONT;

      &-assignments {
        display: flex;
        gap: 5px;
        justify-content: start;
        align-items: center;
        user-select: none;
      }

      &-available-until {
        font-weight: bold;
        color: $ORANGE-10;
      }
    }

    &-sort-buttons {
      display: flex;
      flex-direction: column;

      & > div:first-of-type {
        margin-top: 1px;
        margin-bottom: -10px;
      }

      &-asc {
        & > div:first-of-type {
          color: $FONT_LIGHT_GRAY_COLOR;
        }

        & > div:last-of-type {
          color: $DARK_GRAY_COLOR-2;
        }
      }

      &-desc {
        & > div:first-of-type {
          color: $DARK_GRAY_COLOR-2;
        }

        & > div:last-of-type {
          color: $FONT_LIGHT_GRAY_COLOR;
        }
      }

      &-none {
        div {
          color: $DARK_GRAY_COLOR-2;
        }
      }
    }
  }

  &__status {
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #74737b;
    color: #fff !important;
    border-radius: 4px;
    padding: 6px 8px;
    font: 12px $PRIMARY_FONT;

    &--default {
      background: #74737b;
    }

    // &--available {
    //   background: $COLOR_STATUS_AVAILABLE;
    // }

    // &--accepted {
    //   background: $COLOR_STATUS_ACCEPTED;
    // }

    // &--rejected {
    //   background: $COLOR_STATUS_REJECTED;
    // }

    // &--losted {
    //   background: $COLOR_STATUS_LOSTED;
    // }

    // &--traveling,
    // &--working {
    //   background: $COLOR_STATUS_WORKING;
    //   color: black;
    // }

    // &--fullfilled {
    //   background: $COLOR_STATUS_FULLILLED;
    //   color: black;
    // }

    // &--done {
    //   background: $COLOR_STATUS_DONE;
    //   color: black;
    // }

    // &--cancelled {
    //   background: $COLOR_STATUS_CANCELLED;
    // }

    // &--submitted {
    //   background: $COLOR_STATUS_SUBMITTED;
    // }
  }

  &-drawer {
    &__container {
      font-family: $PRIMARY_FONT;
      left: 0;
      top: 0;
      width: calc(100% - 10px);
      box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.04);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 5px 10px 20px;
      border: 1px solid #ebebeb;
      background-color: $SIDE_DRAWER_BACK_COLOR;

      .tk-dialog__btn-close {
        &:focus-visible::before {
          outline: 2px solid $OUTLINE_COLOR;
          outline-offset: 5px;
          border-radius: 2px;
        }
      }
    }

    &-back {
      margin-bottom: 20px;

      &-btn:before {
        margin: 0;
        color: black;
        font-size: 23px;
      }
    }

    &-matter {
      font-weight: 600;

      hr {
        margin-top: 5px;
      }
    }

    &-mobile-only {
      display: none;
      @media (min-width: 768px) and (max-width: 1024px) {
        display: block;
      }
    }

    &_body {
      border-top: 1px solid #ebebeb;
      padding-top: 16px;
      width: 100%;
    }

    &-btn {
      margin-top: 5px;
      text-align: center;
    }

    &-content {
      font-size: 14px;
      padding: 10px 14px;
    }
    &-label {
      font: 600 14px/20px $PRIMARY_FONT;
    }

    &-value {
      font-size: 14px;
      font-weight: 200;
      margin-top: 5px;
    }

    &_title {
      padding: 10px 0;
      text-align: center;
      font-weight: 600;
    }
  }

  &__modal {
    top: 0 !important;
    left: -11px !important;
    width: 100vw !important;
    height: 100vh !important;
    position: fixed;
    z-index: 51;

    .tv-modal__overlay {
      top: 0 !important;
      left: -10px !important;
      width: 100vw !important;
      height: unset;
    }

    .tv-modal__holder {
      border-radius: 8px;
      top: 63px;
      min-width: 35vw;
      max-width: 620px;
      background-color: #fff;
    }
  }

  &-form {
    width: 100%;

    &__paragraph {
      font: 400 14px/16px $PRIMARY_FONT;
      padding: 0;
      margin: 24px;
    }

    &__label {
      font: 400 16px/20px $PRIMARY_FONT;
    }

    &__textfield {
      padding: 14px 20px;
    }

    &__dropdownlist {
      padding: 7px 13px 5px 13px;
    }

    &__textarea {
      height: 130px;
    }

    &__buttons {
      margin: 0 16px 20px;
    }

    &__cancel {
      border-radius: 6px;
      background: none;
      color: #000000;
      font-weight: 500;
      border: 2px solid #c4c4c4;
      font-weight: 500;
    }

    &__submit {
      border: unset;
      border-radius: 6px;
      width: 100%;
      padding: 10px;
      color: #252733;
      background: #c2c7ce;
      font-weight: 500;
    }

    &-mobiletitle {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      font-family: $PRIMARY_FONT;
    }
  }
}

@media screen and (max-width: 768px) {
  .tv-reportquality {
    &__item {
      &-icon {
        padding: 0px 12px;
        height: 24px;
        order: 1;

        .icon {
          font-size: 24px !important;
        }
      }

      &-status {
        order: 4;
        max-width: unset;
        padding-right: 5px;
      }
      &-text {
        display: flex;
        font: 400 12px/15px $PRIMARY_FONT;
        &.large {
          font: 500 16px/20px $PRIMARY_FONT !important;
          margin-bottom: 5px;
        }
      }
      &-order {
        order: 2;
        max-width: unset;
        padding: 5px;

        .tv-workassignment__item-text {
          font-weight: 600 !important;
        }

        .label {
          // display: none;
        }
      }

      &-name {
        order: 4;
        max-width: unset;
        margin-top: 10px;
        padding-right: 0;
      }

      &-category {
        order: 5;
        max-width: unset;
        margin-top: 10px;

        .label {
          // display: none;
        }

        .tv-workassignment__item-text {
          max-width: 80px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &-datetime {
        order: 1;
        max-width: unset;
        flex: 0 0 25%;
        margin-top: 4px;
      }

      &-type {
        order: 6;
        max-width: unset;
      }

      &-label {
        margin: 0 0 6px 0;
      }

      .list-small-rating > label:before {
        font-size: 12px !important;
      }
    }

    &__list {
      height: calc(100vh - 200px);
      padding-bottom: 70px;
      overflow: hidden;

      &__mobile-fixed-bottom {
        position: fixed;
        display: flex !important;
        flex-direction: row;
        width: 100% !important;
        padding: 16px;
        background-color: #fff;
        text-align: center;
        display: block;
        left: 0;
        bottom: 0;
        z-index: 40;
        width: 100vw;
      }

      &-item {
        height: 160px;
        border-radius: 4px;
        position: relative;
        margin: 10px 0px 10px 0px !important;
        border: 1px solid $BORDER_COLOR;
        &:before {
          content: '';
          position: absolute;
          top: 50px;
          height: 2px;
          width: 100%;
          border-top: 2px solid $BORDER_COLOR;
        }
        &-text {
        }
        &:last-child {
          margin-bottom: 80px !important;
        }
        &-label {
          display: block;
          font-weight: 500;
          margin-right: 5px;
          i {
            color: #74737b;
          }
        }
      }
    }

    &-drawer {
      &__container {
        width: 100%;
        margin: 0;
        background: unset;
        border: unset;
      }
      .tk-dialog__btn-close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 18px !important;
        height: 56px;

        z-index: 4;
        &::after {
          font-size: 15px;
        }
      }

      &-area {
        border: none;
        background: unset;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }

      &-title {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 24px;
        font-family: $PRIMARY_FONT;
      }

      &-mobile-only {
        display: block;
      }
    }

    &__modal {
      left: 0 !important;
      .tv-modal__holder {
        width: calc(100vw);
        max-width: 100vw;
        height: 100vh;
        max-height: 100vh;
        margin-top: 0;
        top: 0;
        border-radius: 0;
        background-color: #fff;
      }
    }

    &__filter-date-range__content {
      position: fixed;
      left: 35px;
      border-radius: 8px;
    }

    &__item-order,
    &__item-datetime,
    &__item-name,
    &__item-category {
      .tv-reportquality__item-label {
        font-size: 13px;
      }
    }
    .tv-reportquality__item-text.large {
      font: 400 16px/20px $PRIMARY_FONT !important;
    }
    &__status {
      padding: 6px 8px;
      letter-spacing: 0.5px;
      font-size: 9px;
    }
    &__wrapper {
      .tk-assignment-creation__container.tk-report-quality-mobile-filter-wrapper {
        height: 100vh;
      }
    }
  }
}

.tv-form__error:empty {
  display: none;
}

.deviation-status-wrapper {
  width: fit-content;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tv-settings__wrapper {
    .tv-settings__tabs-wrapper {
      .tv-reportquality {
        &__item {
          &-icon {
            padding: 0px 12px;
            height: 24px;
            order: 1;

            .icon {
              font-size: 24px !important;
            }
          }

          &-status {
            order: 4;
            max-width: unset;
            padding-right: 5px;
          }
          &-text {
            display: flex;
            font: 400 12px/15px $PRIMARY_FONT;
            &.large {
              font: 500 16px/20px $PRIMARY_FONT !important;
              margin-bottom: 5px;
            }
          }
          &-order {
            order: 2;
            max-width: unset;
            padding: 5px;

            .tv-workassignment__item-text {
              font-weight: 600 !important;
            }

            .label {
              // display: none;
            }
          }

          &-name {
            order: 4;
            max-width: unset;
            margin-top: 10px;
            padding-right: 0;
          }

          &-category {
            order: 5;
            max-width: unset;
            margin-top: 10px;

            .label {
              // display: none;
            }

            .tv-workassignment__item-text {
              max-width: 80px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          &-datetime {
            order: 1;
            max-width: unset;
            flex: 0 0 25%;
            margin-top: 4px;
          }

          &-type {
            order: 6;
            max-width: unset;
          }

          &-label {
            margin: 0 0 6px 0;
          }

          .list-small-rating > label:before {
            font-size: 12px !important;
          }
        }

        &__list {
          height: calc(100vh - 200px);
          padding-bottom: 70px;
          overflow: hidden;

          &__mobile-fixed-bottom {
            position: fixed;
            display: flex !important;
            flex-direction: row;
            width: 100% !important;
            padding: 16px;
            background-color: #fff;
            text-align: center;
            display: block;
            left: 0;
            bottom: 0;
            z-index: 40;
            width: 100vw;
          }

          &-item {
            height: 160px;
            border-radius: 4px;
            position: relative;
            margin: 10px 0px 10px 0px !important;
            border: 1px solid $BORDER_COLOR;
            .tv-display-mobile-hide {
              display: none;
            }
            &:before {
              content: '';
              position: absolute;
              top: 50px;
              height: 2px;
              width: 100%;
              border-top: 2px solid $BORDER_COLOR;
            }
            &-header {
              display: none !important;
            }
            &-text {
            }
            &:last-child {
              margin-bottom: 80px !important;
            }
            &-label {
              display: block;
              font-weight: 500;
              margin-right: 5px;
              i {
                color: #74737b;
              }
            }
          }
        }

        &-drawer {
          &__container {
            width: 100%;
            margin: 0;
            background: unset;
            border: unset;
          }
          .tk-dialog__btn-close {
            position: absolute;
            right: 0;
            top: 0;
            padding: 18px !important;
            height: 56px;

            z-index: 4;
            &::after {
              font-size: 15px;
            }
          }

          &-area {
            border: none;
            background: unset;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
          }

          &-title {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 24px;
            font-family: $PRIMARY_FONT;
          }

          &-mobile-only {
            display: block;
          }
        }

        &__modal {
          left: 0 !important;
          .tv-modal__holder {
            width: calc(100vw);
            max-width: 100vw;
            height: 100vh;
            max-height: 100vh;
            margin-top: 0;
            top: 0;
            border-radius: 0;
            background-color: #fff;
          }
        }

        &__filter-date-range__content {
          position: fixed;
          left: 35px;
          border-radius: 8px;
        }

        &__item-order,
        &__item-datetime,
        &__item-name,
        &__item-category {
          .tv-reportquality__item-label {
            font-size: 13px;
          }
        }
        .tv-reportquality__item-text.large {
          font: 400 16px/20px $PRIMARY_FONT !important;
        }
        &__status {
          padding: 6px 8px;
          letter-spacing: 0.5px;
          font-size: 9px;
        }
        &__wrapper {
          .tk-assignment-creation__container.tk-report-quality-mobile-filter-wrapper {
            height: 100vh;
          }
        }
      }
    }
  }
}
