@import "../../../Share/styles/variables.scss";

.quick-order-cont {
  margin-top: 24px;
  & .form-group {
    // margin-bottom: 0;
    .dropdown {
      height: 48px;
    }
    & .custom-control-label {
      background-color: $WHITE_COLOR !important;
    }
    & label {
      font: 500 16px/20px $PRIMARY_FONT;
      color: #252525;
    }
    .message-receiver-name {
      margin-top: 10px;
    }
    input,
    select {
      font: 400 16px/20px $PRIMARY_FONT !important;
      height: 48px !important;
    }
    & .tv-single-assign-form__control {
      font: 400 16px/20px $PRIMARY_FONT !important;
      height: 48px !important;
      padding: 14px 20px;
      &.tv-single-assign-form__textfield {
        min-height: 72px !important;
        height: auto !important;
      }

      .k-input {
        font: 400 16px/20px $PRIMARY_FONT !important;
      }

      span[role="combobox"] {
        border-bottom: none !important;
      }
    }
    & .invisible-icon {
      .k-i-arrow-s.k-icon {
        color: transparent;
      }
    }
  }
  & .invisible-border {
    .rc-time-picker-input {
      border: none;
    }
    .rc-time-picker-select-option {
      display: none;
    }
  }

  & .w-90 {
    width: 90%;
  }

  & .plus_button {
    margin-top: 8px;
    position: relative;
    left: 4px;
  }

  & .dropdown {
    height: 42px;

    & .rc-time-picker-input {
      height: inherit;
      font: 400 16px/20px $PRIMARY_FONT !important;
      &::-ms-clear {
        display: none;
      }
    }

    ul.panel {
      scroll-behavior: smooth;
      padding-left: 15px;
      top: 50px;
      li {
        font: 100 13px/16px $PRIMARY_FONT !important;
        height: 40px;
        padding: 14px 0;
      }

      &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
        border-radius: 8px;
        height: 40px;
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        background-color: $WHITE_COLOR;
      }
    }
    & .rc-time-picker-select-option {
      height: 48px;
      width: 30px;
      position: absolute;
      right: 0;
      cursor: pointer;

      &::after {
        content: "";
        top: 20px;
        display: inline-block;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid #888888;
      }
    }
  }

  & .tv-form__error {
    width: 100%;
    text-align: end;
    margin-top: 5px;
  }
  & .contact-person-email-edit {
    font-weight: bold;
    cursor: pointer;
    padding-left: 4px;
    &:hover {
      text-decoration: underline;
    }
  }
  & .tv-date-range-picker__dialog {
    left: 0;
  }
  &.side .tv-date-range-picker__dialog {
    position: fixed;
    left: 25px;
    margin-top: 2px;
  }
  & .tv-form__date-field {
    font: 400 16px/20px $PRIMARY_FONT !important;
    padding: 14px 16px !important;

    &:after {
      top: 12px !important;
    }
  }

  & .tv-form__control--error {
    margin-bottom: 0;
    border: 2px solid $BLUE-10 !important;
  }

  & .tv-single-assign-form__dropdownlist {
    padding: 10px 10px !important;
    background-image: linear-gradient(45deg, transparent 50%, #696767 50%),
      linear-gradient(135deg, #696767 50%, transparent 50%), linear-gradient(to right, white, white);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;

    &:after {
      content: "\e933";
      position: absolute;
      font-family: "tfv-icon";
      top: 12px;
      left: 2px;
    }
  }

  .tv-single-assign-form {
    &__label {
      margin: 8px -12px 8px -12px;
    }
  }

  .form-button-cont {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;

    button {
      margin-left: 5px;
      // margin-top: 20px;
    }
  }

  .tv-single-assign-form__input {
    line-height: 10px;
  }
  .invisible-date-border {
    .tv-date-range-picker__container {
      border: none;
      [role="button"] {
        cursor: auto;
      }
      .tv-form__date-field {
        &::after {
          content: none !important;
        }
      }
    }
  }

  .same-width {
    min-width: 110px;
  }

  .add-contact-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .links-add {
    width: 265px;
    font:
      400 14px/16px $PRIMARY_FONT,
      sans-serif;
    border-radius: 4px;
    color: $BLUE-10;
    margin-top: 10px;
    padding: 5px;
    text-align: left;
    cursor: pointer;
    &:hover {
      font-weight: 900;
      // letter-spacing: 0.4px;
    }
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      display: flex;
      flex-direction: row;
      width: fit-content;
    }
  }

  .links-add-container {
    display: flex;
    justify-content: flex-end;
    .links-collapsed {
      width: unset;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
      }
    }
    .links-expand {
      width: unset;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
      }
    }
  }

  .links-add-secondary {
    margin: 0;
    width: 100%;
    text-align: end;
  }
  .tv-form__element-focus {
    &:has(:focus-visible) {
      @media (min-width: 768px) {
        outline: 2px solid $OUTLINE_COLOR;
      }
    }
  }
  input.add-outline,
  textarea.add-outline {
    outline: 2px solid $OUTLINE_COLOR;
  }
  .container {
    input.add-outline {
      outline: 2px solid $OUTLINE_COLOR;
    }
    .tag {
      button {
        &:focus-visible {
          outline: 2px solid $OUTLINE_COLOR;
          outline-offset: -3px;
          // padding-inline: 8px;
        }
      }
    }
  }
  .sms-reminder {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 1;
  }
}

.color-for-text {
  color: rgb(116, 114, 114) !important;
}

.tv-single-assign-form__modal-wrap {
  &.quick-order-preview {
    padding: 10px 33px 10px 33px;
    height: auto;
    margin-bottom: 13px;
    width: auto;
    min-width: 688px;

    .tv-single-assign-form__container {
      width: 100%;
      background: #fff;
      border-radius: 5px;
      box-shadow: none;
      margin: 0 auto 32px auto;
    }

    .tv-single-assign-form__panel--secondary {
      padding: 0px 14px 8px;
      background-color: #fff;
      border-top: none;
      border-bottom: none;
    }

    .edit-text {
      padding: 1px 11px 1px 11px;
      background-color: #6381bb;
      cursor: pointer;
      color: #fff;
      border-radius: 50px;
      margin: 0 10px 0 10px;
      float: right;
    }
  }
}

.tooltip-icon {
  cursor: pointer;
  .tooltip-text {
    max-width: 300px;
    visibility: hidden;
    color: white;
    font: 400 14px/16px $PRIMARY_FONT;
    text-align: left;
    border-radius: 4px;
    padding: 5px 10px;
    position: absolute;
    background-color: $DARK_GRAY_COLOR;
    margin-left: 2px;
    z-index: 55;
    width: max-content;
    .tooltip-text__link {
      display: flex;
      margin: 4px auto;
      color: $BLUE-10;
      cursor: pointer;
      justify-content: start;
    }
  }
  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
}

.plus_button {
  margin-top: 10px;
  position: relative;
  left: -15px;
}

.submit-button-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 0px 12px 0px;
  position: fixed;
  width: 688px;
  height: 75px;
  bottom: 35px;
  background-color: #fff;

  button {
    padding: 10px 80px 10px 80px;
  }
}

.tv-modal {
  &__holder {
    max-height: calc(100vh - 70px);
    z-index: 20;
    padding: 0 10px;
    animation: zoomIn 0.5s;
    max-width: 708px;
    overflow-y: auto;
    position: absolute;

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      top: 20px !important;
    }

    &::-webkit-scrollbar {
      width: 4px;

      &-track {
        background: transparent;
      }

      &-thumb {
        background: #bfbcbc;
        border-radius: 4px;

        &:hover {
          background: #bfbcbc;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .tv-quick-order {
    &__scroll-container {
      height: calc(100vh - 255px) !important;
      & > div {
        overflow-x: hidden !important;
      }
    }
  }
  .tk-assignment-creation {
    &__container {
      left: 0;
      top: 0;
      width: calc(100% - 10px);
      height: auto !important;
      border: 1px solid #e0e0e0;
      background-color: $SIDE_DRAWER_BACK_COLOR;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 5px 10px;
      .tv-workassignment__modal {
        top: -170px;
        &-header {
        }
      }
      .tv-modal__overlay {
        top: -170px !important;
      }
      .tk-dialog__btn-close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 18px !important;
        height: 56px;
        z-index: 4;
        // &:focus-visible {
        //   outline: 1px solid $OUTLINE_COLOR;
        //   outline-offset: -12px;
        //   border-radius: 15px;
        // }
        &::before {
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .tk-assignment-creation {
    &__footer {
      position: relative !important;
      width: 100% !important;
      padding: 0px !important;
    }
  }
}

@media (max-width: 768px) {
  .tk-assignment-creation {
    &__container {
      left: 0;
      width: calc(100vw);
      padding: 16px;
      overflow-y: auto;
      overflow-x: hidden;
      .tk-assignment-list-box_header {
        text-align: center;
      }
      .tk-dialog__btn-close {
        top: 8px;
        // &:focus-visible {
        //   outline: 1px solid $OUTLINE_COLOR;
        //   outline-offset: -12px;
        //   border-radius: 15px;
        // }
      }
    }
    &__footer {
      position: fixed;
      bottom: 0px;
      padding: 14px 24px;
      left: 0;
      width: 100vw;
      background-color: #fff;
    }
  }
  .quick-order-cont {
    margin-bottom: 200px;
    // .dropdown {
    //   margin-bottom: 8px;
    // }
    .calendar-small {
      .rdrMonth {
        padding: 0;
        width: auto;
        .rdrWeekDays,
        .rdrDays {
          width: auto;
        }
      }
    }
    & > div.form-group.col-12.pl-1.mb-5 {
      margin-bottom: 220px !important;
    }

    & .tv-date-range-picker__dialog {
      position: fixed;
      left: 32px !important;
    }
  }
  .tv-quick-order__scroll-container {
    height: calc(100vh - 170px) !important;
    width: calc(100vw - 34px);
    & > div:nth-child(1) {
      overflow-x: hidden !important;
    }
  }
}

.tv-dropdown__footer-conactperson-add {
  display: flex;
  padding: 5px 24px;
  text-align: center;
  cursor: pointer;
  background-color: #e0e0e0;
  font-weight: bolder;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.tv-dropdown__footer-conactperson-add-contactPerson {
  &:focus-visible {
    outline: 2px solid $OUTLINE_COLOR;
    border-radius: 8px;
    outline-offset: -4px;
  }
}

.tv-dropdown__footer-conactperson-add-ordercontactPerson {
  &:focus-visible {
    outline: 2px solid $OUTLINE_COLOR;
    border-radius: 8px;
    outline-offset: -4px;
  }
}

.k-list-container {
  border-radius: 8px !important;
  .k-popup {
    border-radius: 8px !important;
  }
}

.text-field-style {
  height: 40px;
}

.k-list {
  .k-item {
    &:hover {
      color: $BLUE-10;
      font-weight: bold;
    }
    &:hover.k-state-selected {
      color: $BLUE-10;
    }
  }
  .k-item.k-state-selected {
    color: $BLUE-10;
    font-weight: bold;
    background-color: #ebebeb;
  }
  .k-item.k-state-hover.k-state-selected {
    color: $BLUE-10;
  }
}
.k-list-optionlabel {
  &:hover.k-state-selected {
    color: $BLUE-10;
  }
}
.k-list-optionlabel.k-state-selected {
  color: $BLUE-10;
}

.k-dropdown {
  .k-input {
    display: block !important;
  }
  span[role="combobox"] {
    border-bottom: none !important;
  }
}

.date-not-valid {
  outline: 2px solid $BLUE-10;
  border-radius: 4px;
}
