.tv-delete-user-modal {
  z-index: 80 !important;
  position: relative;
  @supports (display: flex) {
    position: fixed;
  }
}

.edit-contact-person-modal {
  z-index: 70 !important;
}