@import "../../../Share/styles/variables.scss";

.tv-modal__wrapper {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
}

.tv-display-flex {
  display: flex;
}

.tv-route-leaving-translation {
  z-index: 40 !important;
  text-align: center;
}

.tv-modal__overlay {
  content: "";
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 12;
}

.tv-modal__container {
  width: 700px;
  z-index: 20;
  // animation: zoomIn 0.5s;
  // overflow-y: auto;
  // position: absolute;
  border-radius: 4px;
  background-color: #fff;
  overflow-x: hidden;
  // top: 60px;
  max-height: 750px;
  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: -webkit-fill-available;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    // border-bottom: 0.5px solid $BORDER_COLOR;
    &-title {
      font: 500 17px/20px $PRIMARY_FONT;
    }
    &-text {
      font: 500 18px/20px $PRIMARY_FONT;
      padding-block: 10px;
    }

    .tv-buttons__type {
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        outline-offset: 6px;
        border-radius: 2px;
      }
    }
  }
  &__body {
    width: 700px;
    padding: 18px 18px 0px 18px;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    overflow-x: hidden;
    @media screen and (max-width: 768px) {
      width: 100%;
      max-height: calc(100vh - 120px);
    }
  }
  &__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
  &__section-2 {
    display: flex;
    margin-bottom: 14px;
  }
  &__full {
    display: flex;
    flex-direction: column;
    align-items: start;
    font: 400 14px/20px $PRIMARY_FONT;
    margin-bottom: 5px;
    label {
      font: 400 16px/20px $PRIMARY_FONT;
    }
    &-box {
      display: flex;
      flex-direction: row;
      align-items: start;
      padding-block: 8px;
      &-label {
        padding-left: 8px;
        font: 400 14px/20px $PRIMARY_FONT;
      }
      input[type="checkbox"] {
        &:checked {
          accent-color: $BLUE-10;
        }
        // &:hover {
        //   background-color: $BLUE-10;
        // }
      }
      &.center {
        align-items: center;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus-within:has(:focus-visible) {
          outline: 2px solid $OUTLINE_COLOR;
          border-radius: 2px;
          outline-offset: 1px;
        }
      }
    }
  }
  &__divider {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__card {
    display: flex;
    flex-direction: column;
    width: 280px;
    align-items: start;
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin-bottom: 10px;
    }
    span {
      .k-multiselect-wrap {
        border-width: 0 0;
      }
    }
    &.right-column {
      width: 420px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    label {
      font: 400 16px/20px $PRIMARY_FONT;
    }
    &-label {
      font: 500 14px/20px $PRIMARY_FONT;
      img {
        width: 12px;
        height: 12px;
        margin-right: 15px;
      }
      i {
        margin-right: 12px;
        color: $FONT_LIGHT_GRAY_COLOR;
        font-size: 12px;
      }
    }
    &-value {
      display: flex;
      flex-direction: row;
      font: 200 14px/20px $PRIMARY_FONT;
      @media screen and (max-width: 768px) {
        text-align: start;
      }
    }
    &-value-2 {
      display: flex;
      flex-direction: column;
      font: 200 14px/20px $PRIMARY_FONT;
      align-items: flex-start;
    }
    .tv-form__date-field {
      display: flex;
      font: 400 16px/20px $PRIMARY_FONT !important;
    }
  }
  &__single-attachment {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__attachment {
    display: flex;
    flex-direction: column;
    align-items: start;
    font: 200 14px/20px $PRIMARY_FONT;
    &__file-path {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font: 200 14px/20px $PRIMARY_FONT;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        text-align: start;
      }
    }
    &__text {
      display: flex;
      margin-top: 5px;
      color: #74737b;
      font: 100 14px/20px $PRIMARY_FONT;
      @media screen and (max-width: 768px) {
        text-align: start;
      }
    }
  }
}

.tv-checkbox-label {
  font: 400 14px/20px $PRIMARY_FONT;
}

.tv-buttons__type {
  background: transparent;
  border: 0;
  padding: 0;
}

.tv-route-leaving-guard-modal__buttons {
  &-translation {
    justify-content: space-between;
    margin-block: 20px 10px;
    margin-inline: 0px !important;
    @media screen and (max-width: 768px) {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
}

.tv-route-leaving-guard-modal__buttons button {
  width: 300px;
  font-size: 14px;
  height: 45px;

  &:focus-visible {
    outline: 2px solid $OUTLINE_COLOR;
    outline-offset: 1px;
  }
}

.tv-single-assign-form__input {
  line-height: 10px;
  width: 100%;
}

.tv-single-assign-form__control {
  &:focus-within:has(:focus-visible) {
    outline: 2px solid $OUTLINE_COLOR;
  }
}

.tv-text-area {
  width: -webkit-fill-available;
  line-height: 2;
  border-radius: 6px;
  border-color: $BORDER_COLOR;

  &.textarea-outline {
    outline: 2px solid $OUTLINE_COLOR;
  }
}

.tv-checkbox {
  background-color: #fff !important;
  font: 400 14px/20px $PRIMARY_FONT !important;
}

.tv-dropzone {
  &__container {
    border: 1px solid $BORDER_COLOR;
    border-radius: 6px;
    padding: 10px;
  }
  &__text {
    font: 400 14px/20px $PRIMARY_FONT;
    padding-bottom: 5px;
    color: #74737b;
  }
  &__upload-btn {
    border: none;
    background: none;
    text-decoration: underline;
    font: 400 14px/20px $PRIMARY_FONT;
    color: $BLUE-10;

    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 2px;
    }
  }
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__uploader {
    display: flex;
    flex-direction: column;
    align-items: start;
    background: aliceblue;
    margin-top: 10px;
    border-radius: 6px;
    padding-inline: 10px;
    padding-block: 5px;
  }
  &__file {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    &__details {
      display: flex;
      flex-direction: row;
      &-image {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        object-fit: fill;
      }
      &-path {
        font: 400 14px/20px $PRIMARY_FONT;
      }
    }
    &__delete {
      color: red;
      display: flex;
      align-items: center;
      justify-content: center;

      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        border-radius: 4px;
        outline-offset: 2px;
      }
    }
  }
  &__option {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    gap: 15px;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }
}
.custom-control-label {
  font: 400 12px/20px $PRIMARY_FONT;
}

.error-message-attachments {
  font: 12px $PRIMARY_FONT;
  font-weight: 300;
  color: #014a8e;
  display: flex;
  justify-content: end;
}
