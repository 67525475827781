@import '../../../Share/styles/variables.scss';

.star-group {
  span {
    -webkit-text-stroke: 1.5px #ffb074;
  }

  .react-stars:focus-visible {
    outline: 2px solid $OUTLINE_COLOR;
    border-radius: 2px;
  }
}
