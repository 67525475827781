@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-booknow {
  text-align: left;

  &__container {
    background-color: $WHITE_COLOR;
    padding: 18px 14px;
    max-height: calc(100vh - 130px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  &_row {
    padding: 0px 12px;
    .rc-time-picker-input {
      border: 1px solid #e0e0e0;
    }
    padding-left: 0px;
    @media screen and (max-width: 768px) {
      // margin-top: 20px;
      padding-left: 0px;
    }
  }
  &_col {
    padding: 8px 8px 0px !important;
  }
  &__link {
    color: $BLUE-10;
    &:hover {
      color: $BLUE-10;
      text-decoration: underline !important;
    }
  }
  &__title {
    padding: 25px 0 0 0;
    align-items: center;
    justify-content: center;
    font: 400 24px/20px $PRIMARY_FONT;

    &-text {
      color: $NAV_BAR_TEXT_COLOR;
      font: 400 16px/20px $PRIMARY_FONT;
      margin-top: 15px;
      text-align: center;
      height: 40px;
      padding: auto 16px;

      &.text-medium {
        font: 300 rem-calc(22) / rem-calc(40) $PRIMARY_FONT;
      }
    }
  }
  &__text {
    font-family: $PRIMARY_FONT;
    font-size: 18px;
    color: #74737b;
    &-date {
      font-family: $PRIMARY_FONT;
      margin-top: 16px;
      margin-bottom: 28px;
      color: #252525;
    }
    &-unit {
      font-family: $PRIMARY_FONT;
    }
  }

  &__modal {
    top: 0 !important;
    left: -11px !important;
    height: 100vh !important;
    position: absolute;
    z-index: 62;
    &-hidden {
      display: none;
    }
    .form-group {
      margin-bottom: 8px !important;
    }
    &-cancel {
      z-index: 62;
      padding-bottom: 24px;
      &__container {
        padding: 0 10px 24px;
        .tv-buttons__element {
          &:focus-visible {
            outline: 2px solid $OUTLINE_COLOR;
            outline-offset: 2px;
          }
        }
      }
      .tv-modal__holder {
        overflow-y: unset;
      }
    }
    &-header,
    &-cancel-header {
      font: 500 18px/20px $PRIMARY_FONT;
      text-align: left;
      padding: 18px 10px;
      height: 56px;
      color: $BLACK_COLOR;
      border-bottom: 1px solid $BORDER_COLOR;
      letter-spacing: 0.2px;
    }
    .title {
      display: flex;
      width: 100%;
      justify-content: center;
      font-family: $PRIMARY_FONT;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 20px;
      margin-top: 48px;
      margin-bottom: 28px;
      color: $COMMON_TEXT_COLOR;
    }
    .text {
      font-size: 18px;
      color: #74737b;
      text-align: center;
      margin-bottom: 30px;
      font-family: $PRIMARY_FONT;
    }
    .tv-modal__overlay {
      top: -175px !important;
      left: -10px !important;
      width: 100% !important;
      height: unset;
      border-radius: 4px;
    }

    .tv-modal__holder {
      border-radius: 8px;
      min-width: 46vw;
      max-width: max-content;
      background-color: #fff;
      width: 620px;
      max-height: 100vh;
      width: 800px;
      top: 63px;
      background-color: #fff;
      overflow-x: hidden;
    }
    &-actions {
      &-button {
        &_cancel {
          margin-top: 24px;
          border: 1px solid $BORDER_COLOR;
          border-radius: 4px;
          width: 100%;
          height: 48px;
          font: 400 16px/20px $PRIMARY_FONT;
          padding: 10px;
          background: #fff;
          margin: 20px 0 2px;

          &:focus-visible {
            outline: 2px solid $OUTLINE_COLOR;
            outline-offset: 2px;
          }
        }

        &_submit {
          margin-top: 24px;
          border: none;
          font: 400 16px/20px $PRIMARY_FONT;
          border-radius: 4px;
          width: 100%;
          height: 48px;
          padding: 10px;
          color: $WHITE_COLOR;
          background-color: $BLUE-10;
          margin: 20px 0 2px;

          &:focus-visible {
            outline: 2px solid $OUTLINE_COLOR;
            outline-offset: 2px;
          }
        }
      }
    }
  }

  &__form {
    margin-top: 12px;
    width: 100%;

    &__buttons {
      padding: 24px 0 10px 0;
      button {
        flex: 1;
        margin: 0 8px;
      }
    }

    & .dropdown {
      height: 42px;

      & .rc-time-picker-input {
        height: inherit;
        font: 400 16px/20px $PRIMARY_FONT !important;
        &::-ms-clear {
          display: none;
        }
      }

      ul.panel {
        scroll-behavior: smooth;
        padding-left: 15px;
        top: 50px;
        li {
          font: 100 13px/16px $PRIMARY_FONT !important;
          height: 40px;
          padding: 14px 0;
        }

        &::-webkit-scrollbar-thumb {
          background: #e0e0e0;
          border-radius: 8px;
          height: 40px;
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          background-color: $WHITE_COLOR;
        }
      }
      & .rc-time-picker-select-option {
        height: 48px;
        width: 39px;
        position: absolute;
        right: 0;
        cursor: pointer;

        &::after {
          content: '';
          top: 20px;
          display: inline-block;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 5px solid #888888;
        }
      }
    }
    & .tv-date-range-picker__dialog {
      left: 0;
    }
    &.side .tv-date-range-picker__dialog {
      position: fixed;
      left: 25px;
      margin-top: 2px;
    }
    & .tv-form__date-field {
      font: 400 16px/20px $PRIMARY_FONT !important;
      padding: 14px 16px !important;

      &:after {
        top: 12px !important;
      }
    }

    & .tv-form__control--error {
      margin-bottom: 0;
      border: 2px solid $BLUE-10 !important;
    }
    & .tv-form__control--error--timePicker {
      & input {
        margin-bottom: 0;
        border: 2px solid $BLUE-10 !important;
      }
    }

    & .tv-single-assign-form__dropdownlist {
      padding: 10px 10px !important;
      background-image: linear-gradient(45deg, transparent 50%, #696767 50%),
        linear-gradient(135deg, #696767 50%, transparent 50%),
        linear-gradient(to right, white, white);
      background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), 100% 0;
      background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
      background-repeat: no-repeat;

      &:after {
        content: '\e933';
        position: absolute;
        font-family: 'tfv-icon';
        top: 12px;
        left: 2px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &__container {
      height: 100vh;
    }
    &_col {
      padding: 8px 0 !important;
    }
    &__text {
      font-family: $PRIMARY_FONT;
      font-size: 16px;
      color: #74737b;
      &-date {
        font-family: $PRIMARY_FONT;
        margin-top: 16px;
        color: #252525;
      }
      &-unit {
        font-family: $PRIMARY_FONT;
      }
    }
    &__container {
      margin-top: 12px;
      padding: 18px 6px;
    }
    &__form {
      margin-bottom: 0;
      form {
        padding-bottom: 82px;
      }
      .p-2 {
        padding: 0.5rem 0 !important;
      }
      &__buttons {
        position: fixed;
        bottom: 0;
        width: 90%;
        background: white;
      }
      .row {
        margin: 0;
      }
    }
    &__modal {
      top: 0 !important;
      left: 0 !important;
      width: 100vw;
      position: fixed;
      height: 100vh;
      z-index: 62 !important;
      .tv-modal__holder {
        position: fixed;
        top: 0;
        max-height: 100vh;
        height: 100vh;
        position: absolute;
        position: relative;
        min-width: 270px !important;
        width: 100%;
        .tv-modal__btn-close {
        }
      }

      &-cancel {
        z-index: 62;
        padding-bottom: 24px;
        .tv-modal__btn-close {
          display: none;
        }
        &-header {
          display: none;
        }
        .title {
          margin-bottom: 24px;
        }
        .text {
          margin-bottom: 4px;
        }
        .tv-modal__overlay {
          top: 0 !important;
          left: 0 !important;
        }

        .tv-modal__holder {
          border-radius: 4px;
          margin: 0 10px;
          max-width: 400px;
          max-height: unset;
          background-color: #fff;
          overflow-x: hidden;
          z-index: 63;
          padding: 0 10px 24px;
          animation: zoomIn 0.5s;
          max-width: 708px;
          overflow-y: auto;
          height: auto;
          width: 332px;
          padding-bottom: 24px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    &_col {
      padding: 8px 8px !important;
    }
    &__text {
      font-family: $PRIMARY_FONT;
      font-size: 16px;
      color: #74737b;
      &-date {
        font-family: $PRIMARY_FONT;
        margin-top: 16px;
        color: #252525;
      }
      &-unit {
        font-family: $PRIMARY_FONT;
      }
    }
    &__container {
      margin-top: 12px;
      padding: 18px 6px;
      height: calc(100vh - 150px);
      overflow-y: auto;
    }
    &__form {
      margin-bottom: 0;
      form {
        padding-bottom: 82px;
      }
      // .p-2 {
      //   padding: 0.5rem 0 !important;
      // }
      &__buttons {
        position: fixed;
        bottom: 0;
        width: calc(100vw - 30px);
        background: white;
        &.tv-booknow__form__buttons--success {
          width: 100%;
          position: relative;
        }
      }
      .row {
        margin: 0;
      }
    }
    &__modal {
      top: 0 !important;
      left: 0 !important;
      width: 100vw;
      position: fixed;
      height: 100vh;
      z-index: 62 !important;
      .tv-modal__holder {
        position: fixed;
        top: 0 !important;
        max-height: unset;
        height: 100%;
        position: relative;
        min-width: 270px !important;
        width: 100%;
        max-width: 100%;
        .tv-modal__btn-close {
        }
      }

      &-cancel {
        z-index: 62;
        padding-bottom: 24px;
        .tv-modal__btn-close {
          display: none;
        }
        &-header {
          display: none;
        }
        .title {
          margin-bottom: 24px;
        }
        .text {
          margin-bottom: 4px;
        }
        .tv-modal__overlay {
          top: 0 !important;
          left: 0 !important;
        }

        .tv-modal__holder {
          border-radius: 4px;
          margin: 0 10px;
          max-width: 400px;
          max-height: unset;
          background-color: #fff;
          overflow-x: hidden;
          z-index: 63;
          padding: 0 10px 24px;
          animation: zoomIn 0.5s;
          max-width: 708px;
          overflow-y: auto;
          height: auto;
          width: 332px;
          padding-bottom: 24px;
        }
      }
    }
  }
}

.tv-available-interpreters {
  &__container {
    padding: 18px 14px 25px;
    max-height: 100vh;
  }
  &__text {
    font-size: 18px;
    color: #74737b;
    margin-bottom: 34px;
    font-family: $PRIMARY_FONT;
  }
  &__title {
    font-family: $PRIMARY_FONT;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: #252525;
    margin-top: 30px;
  }

  &__message {
    font-family: $PRIMARY_FONT;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #74737b;
    margin-top: 20px;
    margin-bottom: 24px;
    .tele-number {
      color: $BLUE-10;
      text-decoration: underline;
    }
  }
  &__header {
    font-family: $PRIMARY_FONT;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #74737b;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
  }
  &__list {
    max-height: calc(100vh - 375px);
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: 1024px) {
      max-height: 100%;
    }
  }
  &__row {
    font-family: $PRIMARY_FONT;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    color: #252525;
    &-active {
      color: white;
      background-color: $ORANGE-10 !important;
    }
    i {
      margin-right: 5px;
      color: #18ad79;
    }
    &:nth-of-type(odd) {
      background-color: #f1f3f4;
    }

    &:nth-of-type(even) {
      background-color: #fff;
    }
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      outline-offset: -1px;
      border-radius: 4px;
    }
  }
}

.tv-booknow-complete {
  &__container {
    padding: 0 14px;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: 1024px) {
      max-height: 100%;
    }
    hr {
      margin: 16px 0 24px;
    }
  }
  &__heading {
    font-family: $PRIMARY_FONT;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    color: #252525;
    margin-top: 24px;
  }
  &__reference-number {
    font-family: $PRIMARY_FONT;
    margin-top: 28px;
    margin-bottom: 36px;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #74737b;
  }
  &__data {
    &-heading {
      font-family: $PRIMARY_FONT;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #252525;
      margin-bottom: 8px;
    }
    &-item {
      font-family: $PRIMARY_FONT;
      display: flex;
      padding: 8px 0;
      font-size: 14px;
      color: #252525;
      &-label {
        font-family: $PRIMARY_FONT;
        flex: 1;
        font-weight: 500;
      }
      &-value {
        flex: 2;
        a {
          color: $BLUE-10;
        }
      }
      &__sent-to {
        margin-top: 16px;
        font-family: $PRIMARY_FONT;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #252525;
      }
    }
  }
  &__buttons {
    padding: 24px 0;
  }
}

.telephone-number {
  color: $BLUE-10;
  text-decoration: underline;
  white-space: nowrap;
}
// .tv-booknow_row {
//   margin-top: 20px;
//   padding-left: 0px;
//   @media screen and (max-width: 768px) {
//     margin-top: 20px;
//     padding-left: 0px;
//   }
// }
.warning-text {
  font-weight: bold;
}
