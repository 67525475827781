@import "../../../Share/styles/variables.scss";

.tv-copybooking-confirmation {
  &__modal {
    z-index: 56 !important;
    .tv-modal__holder {
      border-radius: 4px;
      max-width: 620px;
      background-color: #fff;
      overflow-x: hidden;
    }

    &__header {
      font: 500 18px/20px $PRIMARY_FONT;
      text-align: center;
      padding: 18px 10px;
      color: $BLACK_COLOR;
      letter-spacing: 0.2px;
    }

    &__description {
      padding: 10px;
      font: 500 16px/20px $PRIMARY_FONT;
      text-align: center;
    }
    @media (max-width: 768px) {
      .tv-workassignment__detail-buttons {
        .tv-buttons__element {
          font-size: 15px;
          width: 60%;
        }
        .tv-buttons__element-link {
          width: 38%;
        }
      }
    }
  }
}
