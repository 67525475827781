@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

label {
  margin: 0;
  padding: 0;
}

.rating {
  border: none;
  display: inline-block;

  & > input {
    display: none;
  }

  & > label:before {
    margin: 5px;
    @include font-size(25);
    display: inline-block;
    content: '★';

    font-family: 'tfv-icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & > label {
    color: #ddd;
    float: right;
    font-size: 24px;
    padding-right: 2px;
  }
}

.rating.red>input:checked~label,
.rating.red>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating.red input:enabled+label:hover,
/* hover current star */
.rating.red input:enabled+label:hover~label {
  color: #f93d3d;
}

.rating>input:checked~label,
.rating>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating input:enabled+label.red:hover,
/* hover current star */
.rating input:enabled+label.red:hover~label {
  color: #f93d3d;
}

.rating.red-yellow>input:checked~label,
.rating.red-yellow>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating.red-yellow input:enabled+label:hover,
/* hover current star */
.rating.red-yellow input:enabled+label:hover~label {
  color: #fd974de6;
}

.rating>input:checked~label,
.rating>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating input:enabled+label.red-yellow:hover,
/* hover current star */
.rating input:enabled+label.red-yellow:hover~label {
  color: #fd974de6;
}

.rating.yellow>input:checked~label,
.rating.yellow>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating.yellow input:enabled+label:hover,
/* hover current star */
.rating.yellow input:enabled+label:hover~label {
  color: #ff9b4e;
}

.rating>input:checked~label,
.rating>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating input:enabled+label.yellow:hover,
/* hover current star */
.rating input:enabled+label.yellow:hover~label {
  color: #ffd700;
}

.rating.yellow-green>input:checked~label,
.rating.yellow-green>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating.yellow-green input:enabled+label:hover,
/* hover current star */
.rating.yellow-green input:enabled+label:hover~label {
  color: #ff9b4e;
}

.rating>input:checked~label,
.rating>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating input:enabled+label.yellow-green:hover,
/* hover current star */
.rating input:enabled+label.yellow-green:hover~label {
  color: #ff9b4e;
}

.rating.green>input:checked~label,
.rating.green>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating.green input:enabled+label:hover,
/* hover current star */
.rating.green input:enabled+label:hover~label {
  color: #ff9b4e;
}

.rating>input:checked~label,
.rating>input[data-hightlight=true]~label,
/* show gold star when clicked */
.rating input:enabled+label.green:hover,
/* hover current star */
.rating input:enabled+label.green:hover~label {
  color: #ff9b4e;
}

/* hover previous stars in list */

.rating>input:checked:enabled+label.red:hover,
/* hover current star when changing rating */
.rating>input:checked:enabled~label.red:hover,
.rating>input[data-hightlight=true]~label.red:hover,

.rating>label.red:hover~input:checked:enabled~label,
/* lighten current selection */
.rating>input:checked:enabled~label.red:hover~label {
  color: #ff9b4e !important;
}

.rating>input:checked:enabled+label.red-yellow:hover,
/* hover current star when changing rating */
.rating>input:checked:enabled~label.red-yellow:hover,
.rating>input[data-hightlight=true]~label.red-yellow:hover,

.rating>label.red-yellow:hover~input:checked:enabled~label,
/* lighten current selection */
.rating>input:checked:enabled~label.red-yellow:hover~label {
  color: #ff9b4e !important;
}

.rating>input:checked:enabled+label.yellow:hover,
/* hover current star when changing rating */
.rating>input:checked:enabled~label.yellow:hover,
.rating>input[data-hightlight=true]~label.yellow:hover,

.rating>label.yellow:hover~input:checked:enabled~label,
/* lighten current selection */
.rating>input:checked:enabled~label.yellow:hover~label {
  color: #ff9b4e !important;
}

.rating>input:checked:enabled+label.yellow-green:hover,
/* hover current star when changing rating */
.rating>input:checked:enabled~label.yellow-green:hover,
.rating>input[data-hightlight=true]~label.yellow-green:hover,

.rating>label.yellow-green:hover~input:checked:enabled~label,
/* lighten current selection */
.rating>input:checked:enabled~label.yellow-green:hover~label {
  color: #ff9b4e !important;
}

.rating>input:checked:enabled+label.green:hover,
/* hover current star when changing rating */
.rating>input:checked:enabled~label.green:hover,
.rating>input[data-hightlight=true]~label.green:hover,

.rating>label.green:hover~input:checked:enabled~label,
/* lighten current selection */
.rating>input:checked:enabled~label.green:hover~label {
  color: #ff9b4e !important;
}

.detailed-rating {
  & > label:before {
    font-size: 24px !important;
    -webkit-text-stroke: 1px #ffb074;
  }
}

.list-rating {
  & > label:before {
    font-size: 1rem !important;
  }

  & > label {
    padding-right: 0px !important;
  }
}

.list-rating-container {
  & > div {
    margin-bottom: 10px;
    margin-top: 0px;
  }

  & > div > .rating {
    margin-left: 20%;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-content: center;
    width: 21.6%;
    padding-left: 5px;

    @media only screen and (max-width: 1412px) {
      & > div {
        margin-top: -20px !important;
      }
    }

    & > div {
      margin-top: -5px;
      min-width: 184px;
    }

    & > div > .rating {
      margin-left: 0px;
    }
  }

  align-items: center;
  justify-content: center;
  text-align: center;
  width: 21.6%;
  padding-left: 5px;

  & > div.tv-workassignment__item-status {
    width: 100% !important;
  }
}

.tv-rate-submit-btn {
  padding: 6px 4px 2px !important;
  font-size: 12px !important;
  margin-left: 32px;
}

textarea {
  padding: 14px 20px;
  resize: none;
}

.comment-text {
  font-style: italic;
  font-size: 0.875rem;
  color: #313131 !important;
  padding: 2px 6px;
  display: flex;
  flex-direction: row;
}

.icon-comment-2:before {
  color: #c0c2c3 !important;
  margin: 1px 9px 0px 0px;
}

.show-remainig-char {
  font-style: italic;
  font-size: 12px;
  display: block;
  text-align: right;
}

.redirect-checkbox {
  padding: 0px 5px;
}

.redirect-checkbox label {
  font-size: 0.8125rem;
  font-weight: 300;
}

.good-rating-message {
  border-left: 5px solid #17c917;
  font: 0.725rem $PRIMARY_FONT, sans-serif;
  padding: 5px;
  margin: 5px 0px !important;
  background-color: #c7f055;
}

.rating-label {
  margin: 5px 0px;
  font: 0.875rem $PRIMARY_FONT, sans-serif;
}

.send-feedback-btn {
  align-items: flex-end;
  margin-left: auto;
  width: 150px !important;
  margin-right: 15px;
}

.tv-single-assign-form__dropdownlist {
  padding: 10px 10px !important;
  background-image: linear-gradient(45deg, transparent 50%, #696767 50%),
    linear-gradient(135deg, #696767 50%, transparent 50%),
    linear-gradient(to right, white, white) !important;
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
  background-repeat: no-repeat !important;

  &:after {
    content: '\e933';
    position: absolute;
    font-family: 'tfv-icon';
    top: 12px;
    left: 2px;
  }
}

.feedback-create-deviation {
  color: $BLUE-10;
  font-family: $PRIMARY_FONT;
  font-size: 16px;
  line-height: 23px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  & > .tv-rate-submit-btn {
    margin-left: 15px;
    margin-right: 0px;
  }
}

.wrapper-stars-button {
  display: flex;
  flex-direction: column;
}
