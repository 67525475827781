@media (max-width: 768px) {
  .tv-right-status {
    padding: 0px 16px 0 16px !important;
    margin-bottom: 24px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);

    .show-direct-book {
      display: none;
    }

    .navigation {
      display: none;
    }

    .menu-button {
      display: inline-block !important;
      font-size: 24px;
      border: none;
      top: -3px;
      background: none;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        border-radius: 4px;
        outline-offset: 2px;
      }
    }

    .icon-search {
      color: $BLACK_COLOR;
    }

    .mobile-nav {
      margin-left: 0px !important;
      align-items: center;
      display: flex;
    }

    .tv-avatar {
      width: unset !important;
      margin-right: unset !important;
      display: inline-grid !important;
      right: 10px;
    }

    .menu-button {
      display: none;
    }
  }

  a:hover {
    text-decoration: none;
  }
}

.tv-filter-list {
  &__element .tv-avatar-list__menu {
    position: fixed !important;
    top: 60px !important;
    right: 8px;
  }
  &__user {
    &-name {
      display: none;
    }
  }
}

.tv-search {
  display: none;
}

@media (min-width: 678px) and (max-width: 990px) and (orientation: landscape) {
  .tv-right-status {
    padding: 0px 16px 0 16px !important;
    margin-bottom: 24px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);

    .show-direct-book {
      display: none;
    }

    .navigation {
      display: none;
    }

    .menu-button {
      display: inline-block !important;
      font-size: 24px;
      border: none;
      top: -3px;
      background: none;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        border-radius: 4px;
        outline-offset: 2px;
      }
    }

    .icon-search {
      color: $BLACK_COLOR;
    }

    .mobile-nav {
      margin-left: 0px !important;
      align-items: center;
      display: flex;
    }

    .tv-avatar {
      width: unset !important;
      margin-right: unset !important;
      display: inline-grid !important;
      right: 10px;
    }

    .menu-button {
      display: none;
    }

    .tv-filter-list__element {
      .tv-notification_header {
        margin-right: 20px;
      }
    }

    .direct-booking-wrapper {
      display: none;
    }

    .quick-booking-wrapper {
      flex-grow: 1;
      margin-right: 15px;
      .show-direct-book {
        display: flex;
      }
    }
  }

  a:hover {
    text-decoration: none;
  }
}

@media (min-width: 678px) and (max-width: 1024px) {
  .tv-right-status {
    padding: 0px 16px 0 16px !important;
    margin-bottom: 24px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);

    .direct-booking-wrapper {
      .booking-button {
        display: none !important;
      }
    }

    .quick-booking-wrapper {
      .booking-button {
        display: none !important;
      }
    }

    .navigation {
      display: none;
    }

    .menu-button {
      display: inline-block !important;
      font-size: 24px;
      border: none;
      top: -3px;
      background: none;
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        border-radius: 4px;
        outline-offset: 2px;
      }
    }

    .tv-avatar {
      width: unset !important;
      margin-right: unset !important;
      display: inline-grid !important;
      right: 10px;
    }

    .mobile-nav {
      margin-left: 0px !important;
      align-items: center;
      display: flex;
    }

    .tv-filter-list__element {
      .tv-notification_header {
        margin-right: 20px;
      }
    }
  }
}
