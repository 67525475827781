@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-single-assign-form {
  &__control {
    border: 1px solid $BORDER_COLOR;
    border-radius: 6px;
    width: 100%;
    font: 400 16px/20px $PRIMARY_FONT;
    outline: none;
    color: $NAV_BAR_TEXT_COLOR;
    margin-bottom: 8px;
    & span[role='listbox'] {
      border-bottom: none;
    }
  }
}

.type_wrapper {
  margin-left: 15px;
  width: 93%;
}

.label {
  font: 16px $PRIMARY_FONT;
  margin: 0;
  padding: 0;
}
.tv-workassignment__filter-date-range {
  &__content {
    .calendar-small .rdrDayToday {
      cursor: pointer;
    }

    .tv-buttons__element {
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        outline-offset: 2px;
      }
    }
  }

  .tv-workassignment__date_picker {
    &-input {
      user-select: none;
      &::selection {
        color: black;
      }
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
      }
    }
  }
}

.tv-workassignment__wrapper {
  .tv-search-box {
    .search-box-focus {
      outline: 2px solid $OUTLINE_COLOR;
    }
  }
}
