.dashboard {
  &__filter-holder {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, auto);
    column-gap: 10px;
    margin-top: 15px;
    border-radius: 15px;
    border: 1px solid #efefef;
    background-color: white;
    padding: 20px;
  }
  &__chart-holder {
    margin-top: 15px;
    padding: 10px;
    display: grid;
    display: -ms-grid;
    background-color: rgba(255, 255, 255, 0.212);
    color: rgb(113, 100, 100);
    border-radius: 15px;
    font-family: $PRIMARY_FONT, sans-serif;
    border: 1px solid rgb(171, 171, 171);
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.031),
      0 3px 6px rgba(0, 0, 0, 0);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.031), 0 3px 6px rgba(0, 0, 0, 0);
  }
  &__display-card {
    // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 5px;
    display: flow-root;

    width: 100%;
    height: auto;
    border: 1px solid #efefef;
    background-color: white;
    &-header {
      display: block;
      width: 100%;
      font-size: 1.2rem;
      padding: 15px;
    }
    &_nodata {
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding-top: 50px;
      width: 100%;
      height: 100%;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      background-color: rgba(255, 255, 255, 0.65);
    }
  }

  &__samll-display-card {
    padding: 10px 5px;
    display: grid;
    &:nth-child(1) {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: 1;
      grid-row: 1;
    }
    &:nth-child(2) {
      -ms-grid-column: 2;
      grid-column: 2;
      -ms-grid-row: 1;
      grid-row: 1;
    }
    height: auto;
    width: 100%;
    align-items: center;
    border: 1px solid #efefef;
    background-color: white;
    &-header {
      display: block;
      width: 100%;
      font-size: 1.2rem;
      padding: 15px;
      span {
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &_samll-detail-card {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    &-header {
      display: block;
      width: 100%;
      font-size: 1rem;
      padding: 15px;
      span {
        width: 190px;
        white-space: initial;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-content {
      display: grid;
      width: 100%;
      height: fit-content;
      max-height: 2rem;
      font-weight: 100;
      float: left;
      background-color: white;
      color: #716464;
      font-size: 1rem;
      padding: 15px;
    }
  }
}

.report-card {
  &-header {
    font-family: $PRIMARY_FONT, sans-serif;
    font-size: 1rem;
  }
}
.round-border {
  border-radius: 10px;
}
.p-1 {
  padding: 5px;
}
.auto {
  &-row-1 {
    display: grid;
    display: -ms-grid;
    grid-template-rows: auto;
    -ms-grid-rows: minmax(min-content, 1fr);
  }
  &-row-2 {
    display: grid;
    display: -ms-grid;
    grid-template-rows: auto auto;
    -ms-grid-rows: minmax(min-content, 1fr) 2px minmax(min-content, 1fr);
  }
  &-col-1 {
    display: grid;
    display: -ms-grid;
    grid-template-columns: auto;
    grid-row-gap: 15px;
  }

  &-col-2 {
    display: grid;
    display: -ms-grid;
    grid-template-columns: auto auto;
    -ms-grid-columns: minmax(min-content, 1fr) 2px minmax(min-content, 1fr);
    grid-gap: 20px;
  }
}
.exact-col-4 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-gap: 10px;
}
.exact-col-2 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-gap: 20px;
}
.exact-col-1 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-gap: 20px;
}

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.float-filter {
  &-icon-box {
    background-color: $ORANGE-10;
    border-radius: 10px 10px 0px 10px;
    width: 60px;
    color: white;
    height: 60px;
    box-shadow: 0 3px 6px #0000001a, 0 3px 6px #00000078, 0 0px 0px #0000001a,
      0 3px 6px #0000001a;
    span {
      font-size: 2rem;
      padding: 10px;
    }
  }
  &-content {
    background-color: $ORANGE-10;
    color: white;
    align-self: end;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    height: 40px;
    width: 100%;
    box-shadow: 0 3px 6px #0000001a, 0 3px 6px #00000078, 0 3px 6px #0000001a,
      0 0px 0px #0000001a;
    span {
      float: left;
      padding: 5px;
    }
  }
  &-clear-btn {
    width: auto;
    border-left: 3px white solid;
    height: auto;
    cursor: pointer;
    font-size: 1.4rem;
  }
  &-clear-btn:hover {
  }
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto auto;
  -ms-grid-columns: minmax(min-content, 1fr) 2px minmax(min-content, 1fr);
  position: fixed;
  z-index: 1000000;
  bottom: 35%;
  right: 8%;
}
.text-float-right {
  padding-right: 5% !important;
  max-width: 200px !important;
  text-align: right !important;
}

.report___sidemenu-scroll {
  height: 200px !important;
  width: inherit !important;
}
