@import '../../../../Share/styles/variables.scss';

.tv-notification-icon {
  &__container {
    height: 30px;
    padding-top: 4px;
  }
  &__button {
    border: none;
    outline: none;
    background-color: transparent;

    @media (max-width: 768px) {
      padding-top: 3px;
    }
    i {
      color: #74737b;
    }

    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 4px;
      outline-offset: 2px;
    }
  }
  &-active {
    color: $BLUE-10 !important;
    background-image: url('/images/bell-active.svg');
    height: 17.72px;
    width: 18.82px;
    background-size: cover;
    background-position-y: center;
    margin: auto;
  }
  &-inactive {
    color: $BLUE-10 !important;
    background-image: url('/images/bell-inactive.svg');
    height: 17.72px;
    width: 18.82px;
    background-size: cover;
    background-position-y: center;
    margin: auto;
  }
  &__count {
    width: 16.44px;
    height: 16.44px;
    border: 1.5px solid $BLUE-10;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 15px;
    left: 15px;
    font-size: 10px;
    color: $BLUE-10;
  }
}
