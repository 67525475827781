@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-reportquality {
  text-align: left;

  &__container {
    width: 100%;
  }

  &__wrapper {
    // flex: 1.4 1;
    // justify-content: center;
    // align-items: center;
    // padding-top: 22px;
    background-color: $WHITE_COLOR;
  }

  &__btn {
    &-filter {
      font-family: $PRIMARY_FONT;
      display: block;
      background-color: #c2c7ce;
      border: 1px solid #c2c7ce;
      border-radius: 8px;
      padding: 15px;
      width: 90%;
      margin: 10px;
    }
  }

  &-sendbutton {
    margin: 10px 15px 10px 10px;
    padding: 16px;
    width: 100%;
    border: none;
    background: $DARK_GRAY_COLOR-2;
    border-radius: 4px;
    font-weight: 600;
  }

  &__list {
    &__mobile-fixed-bottom {
      display: none;
    }

    &-item {
      cursor: pointer;
      margin: 10px 15px 10px 10px;
      padding: 18px 0;
      font-family: 'PT Serif', serif;
      z-index: 10;
      background: #f4f6f9;

      border-radius: 8px;
      display: flex;
      justify-content: space-around;
      width: 100%;

      sales &-child {
        margin: 0 13px;
        box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2),
          0 10px 18px -12px rgba(58, 68, 131, 0.2);
      }
    }
  }

  &__item {
    &-status {
      // width: 21.6%;
      align-items: center;
      justify-content: center;

      &--1 {
        background-color: $COLOR_STATUS_NEWREGISTERED !important;
      }

      &--2 {
        background-color: $COLOR_STATUS_UNDERINVESTIGATE !important;
      }

      &--3 {
        background-color: $COLOR_STATUS_COMPLETED !important;
      }
    }

    &-icon {
      padding: 15px 40px;

      .icon {
        &:before {
          color: black;
        }
      }
    }

    // &-order {
    //   // width: 30%;
    //   margin-right: 10px;
    // }

    // &-name {
    //   // width: 29.1%;
    // }

    // &-category {
    //   // width: 16.7%;
    // }

    // &-datetime {
    //   // width: 27.6%;
    // }

    // &-type {
    //   // width: 12%;
    //   margin-right: 10px;
    // }

    &-more_button {
      outline: none;
      border: none;
      background: none;
      text-transform: uppercase;
      font-size: 14px;

      &:after {
        content: '\e931';
        font-family: 'tfv-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        color: #c5c5c5;
        margin-left: 4px;
        font-size: 10px;
        color: $BLACK_COLOR;
      }
    }

    &-menu {
      // width: 7%;
      align-items: center;
      justify-content: center;

      &-button {
        &:before {
          @include tv-tfv-font;
          font-size: 1.5rem;
        }
      }
    }

    &-label {
      font-size: 0.975rem;
      color: $BLACK_COLOR;
      margin: 0 0 11px 0;
      font-family: $PRIMARY_FONT;
    }

    &-text {
      color: $NAV_BAR_TEXT_COLOR;
      font-size: 14px;
      font-weight: 300;
      font-family: $PRIMARY_FONT;

      &-available-until {
        font-weight: bold;
        color: $ORANGE-10;
      }
    }
  }

  &__status {
    font: 12px $PRIMARY_FONT;
    color: $WHITE_COLOR;
    border-radius: 7px;
    text-transform: uppercase;
    padding: 12px 22px;
    letter-spacing: 0.5px;

    &--default {
      background: $COLOR_REPORT_STATUS;
    }

    // &--available {
    //   background: $COLOR_STATUS_AVAILABLE;
    // }

    // &--accepted {
    //   background: $COLOR_STATUS_ACCEPTED;
    // }

    // &--rejected {
    //   background: $COLOR_STATUS_REJECTED;
    // }

    // &--losted {
    //   background: $COLOR_STATUS_LOSTED;
    // }

    // &--traveling,
    // &--working {
    //   background: $COLOR_STATUS_WORKING;
    //   color: black;
    // }

    // &--fullfilled {
    //   background: $COLOR_STATUS_FULLILLED;
    //   color: black;
    // }

    // &--done {
    //   background: $COLOR_STATUS_DONE;
    //   color: black;
    // }

    // &--cancelled {
    //   background: $COLOR_STATUS_CANCELLED;
    // }

    // &--submitted {
    //   background: $COLOR_STATUS_SUBMITTED;
    // }
  }

  &-drawer {
    &__container {
      font-family: $PRIMARY_FONT;
      left: 0;
      top: 0;
      width: calc(100% - 10px);
      box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.04);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 5px 10px 20px;
      border: 1px solid #ebebeb;
    }

    &-back {
      margin-bottom: 20px;

      &-btn:before {
        margin: 0;
        color: black;
        font-size: 23px;
      }
    }

    &-matter {
      font-weight: 600;

      hr {
        margin-top: 5px;
      }
    }

    &-mobile-only {
      display: none;
    }

    &_body {
      border-top: 1px solid #ebebeb;
      padding-top: 16px;
      width: 100%;
    }

    &-btn {
      margin-top: 5px;
      text-align: center;
    }

    &-content {
      font-size: 14px;
      padding: 10px 14px;
    }

    &-value {
      font-size: 14px;
      font-weight: 200;
      margin-top: 5px;
    }

    &_title {
      padding: 10px 0;
      text-align: center;
      font-weight: 600;
    }
  }

  &__modal {
    top: 0 !important;
    left: -11px !important;
    width: 100vw !important;
    height: 100vh !important;
    position: absolute;

    .tv-modal__overlay {
      top: -175px !important;
      left: -10px !important;
      width: 100vw !important;
      height: unset;
    }

    .tv-modal__holder {
      border-radius: 8px;
      min-width: 35vw;
      max-width: 620px;
      background-color: #fff;
    }
  }

  &-form {
    width: 100%;

    &__paragraph {
      padding: 7px 15px 2px;
    }

    &__label {
      font-weight: 500;
      font-family: $PRIMARY_FONT;
    }

    &__textfield {
      padding: 14px 20px;
    }

    &__dropdownlist {
      padding: 7px 13px 5px 13px;
    }

    &__textarea {
      height: 130px;
    }

    &__buttons {
      margin: 0 16px 20px;
    }

    &__cancel {
      border-radius: 6px;
      background: none;
      color: #000000;
      font-weight: 500;
      border: 2px solid #c4c4c4;
      font-weight: 500;
    }

    &__submit {
      border: unset;
      border-radius: 6px;
      width: 100%;
      padding: 10px;
      color: #252733;
      background: #c2c7ce;
      font-weight: 500;
    }

    &-mobiletitle {
      text-align: center;
      font-weight: 600;
      font-size: 35px;
      font-family: $PRIMARY_FONT;
    }
  }
}

@media screen and (max-width: 768px) {
  .tv-reportquality {
    &__item {
      &-icon {
        padding: 0px 12px;
        height: 24px;
        order: 1;

        .icon {
          font-size: 24px !important;
        }
      }

      &-status {
        order: 4;
        max-width: unset;
        padding-right: 5px;
      }

      &-order {
        order: 2;
        max-width: unset;
        padding: 5px;

        .tv-workassignment__item-text {
          font-weight: 600 !important;
        }

        .label {
          // display: none;
        }
      }

      &-name {
        order: 4;
        max-width: unset;
        margin-top: 10px;
        padding-right: 0;
      }

      &-category {
        order: 5;
        max-width: unset;
        margin-top: 10px;

        .label {
          // display: none;
        }

        .tv-workassignment__item-text {
          max-width: 80px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &-datetime {
        order: 1;
        max-width: unset;
        flex: 0 0 25%;
        margin-top: 4px;
      }

      &-type {
        order: 6;
        max-width: unset;
      }

      &-label {
        margin: 0 0 6px 0;
      }

      .list-small-rating > label:before {
        font-size: 12px !important;
      }
    }

    &__list {
      height: calc(100vh - 200px);
      padding-bottom: 70px;
      overflow: hidden;

      &__mobile-fixed-bottom {
        position: fixed;
        background-color: #fff;
        text-align: center;
        display: block;
        left: 0;
        bottom: 0;
        width: 100vw;
      }

      &-item {
        margin: 10px 15px 10px 2px !important;
      }
    }

    &-drawer {
      &__container {
        width: 100%;
        margin: auto;
        position: fixed;
        background: unset;
        border: unset;

        .tk-dialog__btn-close {
          position: absolute;
          right: 0;
          top: 0;
          padding: 18px !important;
          height: 56px;
          z-index: 4;

          &::after {
            font-size: 15px;
          }
        }
      }

      &-area {
        border: 1px solid #ebebeb;
        background: unset;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }

      &-mobile-only {
        display: block;
      }
    }

    &__filter-date-range__content {
      position: fixed;
      left: 35px;
      border-radius: 8px;
    }

    &__item-order,
    &__item-datetime,
    &__item-name,
    &__item-category {
      .tv-reportquality__item-label {
        font-size: 13px;
      }
    }

    &__status {
      padding: 9px 3px;
      letter-spacing: 0.5px;
      font-size: 9px;
    }
  }
}

.tv-form__control--error {
  margin-bottom: 0;
  border: 2px solid $BLUE-10 !important;
}

.tv-form__error:empty {
  display: none;
}

textarea.tv-single-assign-form__control {
  height: auto !important;
  padding: 16px !important;
  max-height: 120px !important;
  min-height: 48px;
}
