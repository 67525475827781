@media(max-width: 768px) {
  .tv-left-pannel {

    &__wrapper {
      display: none;
    }

  }

  .tv-right-pannel-wrapper {
  
    .tv-right-status {
      padding: 22px 32px;
      max-width: 100%;       
        .tv-avatar {
          width: 38px;
          display: block;
          // position: relative;
          margin-right: 10px;
        }
      
    }
  }
}