@import "./variables.scss";

.tv-bg-login-page {
  background-color: $WHITE_COLOR;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.tv-login-page {
  &__wrapper {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      height: 100vh;
    }
  }

  &__form-container {
    display: -webkit-flex;
    display: flex;
    margin: auto;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
  }
  &__right {
    &-wrapper {
      background-color: $WHITE_COLOR;
      flex: 1.4;
      justify-content: center;
      align-items: center;

      .tab-panel-content {
        padding: 52px;
        @include scroll-bar(100vh);
      }
      @media screen and (max-width: 768px) {
        flex: none;
        margin-top: 25px;
      }
    }

    &-footer {
      &-wrapper {
        position: absolute;
        font-family: $PRIMARY_FONT;
        bottom: 0;
        margin: auto auto 26px auto;
        @media screen and (max-width: 768px) {
          position: relative;
          margin: 26px auto 26px auto;
        }
      }
      &-text {
        font: 0.875rem/1.5rem $PRIMARY_FONT;
        span {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}

.tv-login {
  &__wrapper {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 502px;
    @media screen and (max-width: 768px) {
      box-shadow: none;
      border: none;
      width: auto;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2px;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      line-height: normal;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 3px;
    .k-animation-container.k-animation-container-relative {
      display: flex;
      flex-direction: column;
    }
    .k-item {
      white-space: nowrap;
    }
  }

  &__description {
    padding: 10px 30px 10px 30px;
    font: 0.875rem/1.5rem $PRIMARY_FONT;
    text-align: center;
    color: $NAV_BAR_TEXT_COLOR;
  }

  &__form {
    &-wrapper {
      width: 100%;
      padding: 30px 56px 30px 56px;
      font-family: $PRIMARY_FONT;
      margin-top: 16px;
      .tv-form__control {
        border: 1px solid #e0e0e0;
        height: 48px;
        box-sizing: border-box;
        padding: 14px 16px;
        border-radius: 4px;
        margin: 8px 0;
        outline: none;
        font-weight: 400;
        background-color: #ffffff !important;
        &.add-outline {
          outline: 2px solid $OUTLINE_COLOR;
          outline-offset: -1px;
        }
        font-size: 16px;
      }
      @media screen and (max-width: 768px) {
        margin: 16px;
        width: calc(100vw - 32px);
        padding: 0 16px;

        box-shadow: none;
      }
    }
    &-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: #252525;
      margin-bottom: 8px;
    }
    &-email-input {
      width: 100%;
    }
    &-interpreter-id-label {
      margin-top: 15px;
    }
    &-interpreterId-input {
      width: 100%;
    }
    &-submit {
      &.tv-buttons__element {
        background: $BLUE-10;
        border-color: $BLUE-10;
        width: 100%;
        margin-top: 25px;
        padding: 13px 0px 13px 0px;
        font-weight: 300;
        line-height: 1.4375rem;
        &--disable {
          background: $TEXT_INACTIVE_COLOR;
          border-color: $TEXT_INACTIVE_COLOR;
        }
        &:focus-visible {
          outline: 2px solid $OUTLINE_COLOR;
          outline-offset: 2px;
        }
      }
    }
  }

  &__reset-password {
    display: flex;
    margin: 16px auto;
    color: $BLUE-10;
    justify-content: center;
    cursor: pointer;
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      outline-offset: 5px;
      border-radius: 2px;
    }
  }
  &__accessibility-statement-link{
    display: flex;
    margin: 4px auto;
    color: $BLUE-10;
    justify-content: center;
    cursor: pointer;
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      outline-offset: 5px;
      border-radius: 2px;
    }
  }
  &__controller {
    // &:focus-visible {
    //   outline: 1px solid $OUTLINE_COLOR;
    //   outline-offset: -1px;
    // }
  }

  &__check-contact-person {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
}

.k-tabstrip-top {
  .k-tabstrip-items {
    border-bottom-width: 0px;
  }
  .k-content {
    padding-inline: 0px;
    padding-top: 0px;
  }
}

.k-tabstrip-items {
  .k-link {
    font: 400 14px/20px $PRIMARY_FONT !important;
    text-transform: capitalize;
  }
  .k-item.k-state-active {
    &::after {
      border-color: $BLUE-10 !important;
    }
  }
}

.k-widget.k-header.k-floatwrap.k-tabstrip.k-tabstrip-top {
  ul {
    display: flex;
    flex-direction: row;
    li {
      width: 50%;
    }
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 4px;
    }
  }
}
