@import '../../../Share/styles/variables.scss';

.tv-search-box {
  position: relative;
  input {
    background-color: #f1f3f4 !important;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    height: 48px;
    font-size: 16px;
    font: 400 16px/18px $PRIMARY_FONT, sans-serif;
    margin: 0px;
    width: 100%;
    padding: 0px 12px 0 40px;
  }
  i {
    position: absolute;
    color: #74737b;
    top: 14px;
    left: 16px;
  }
}
