@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

.tv-not-found-page {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;

  &__image {
    height: 342px;
    width: 324px;
    background-image: url('/images/not-found.svg');
    background-size: cover;
    background-position-y: center;
    margin: auto;
  }

  &__title {
    font: rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: $BLUE-10;
  }
}
