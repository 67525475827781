@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

$MARGIN-LEFT-HEADER: 190px;

.tv-profile {
  &__wrapper {
    width: 800px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
    margin: 30px auto 0 auto;
    min-height: 100vh;
  }

  .tx-input-text--border {
    width: 100%;
  }

  .tv-row {
    margin-bottom: 32px;
  }

  &__header-wrapper {
    height: 134px;
    background-color: #f9f9f9;
    padding: 66px 72px 0px 72px;
    position: relative;
  }

  &__name {
    color: #353535;
    font: 500 rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
    letter-spacing: -0.2px;
    margin-left: $MARGIN-LEFT-HEADER;
  }

  &__client-number {
    font: rem-calc(14) / rem-calc(20) $SECONDARY_FONT;
    letter-spacing: 0.3px;
    color: $DARK_GRAY_COLOR;
    margin-left: $MARGIN-LEFT-HEADER;
  }

  &__avatar {
    position: absolute;
    top: 49px;
  }

  &__avatar-img {
    border-radius: 50%;
    width: 158px;
    height: 158px;
    object-fit: cover;
  }

  &__content-wrapper {
    padding: 130px 72px 20px 72px;
  }

  &__input-label {
    font-family: $PRIMARY_FONT, sans-serif;
    color: $NAV_BAR_TEXT_COLOR;
    font: rem-calc(14) / rem-calc(24) $PRIMARY_FONT;
    margin-bottom: 5px;
  }

  &__change-avatar-btn,
  &__edit-btn {
    margin-left: $MARGIN-LEFT-HEADER;
    margin-bottom: 60px;

    &.tv-buttons__element {
      font-weight: 300;
      padding: 13px 31px;
      line-height: 23px;
    }
  }

  &__change-avatar-btn {
    display: flex;
    width: 220px;
    justify-content: center;

    & + input[type='file'] {
      display: none;
    }

    &:before {
      content: '\e928';
      @include tv-tfv-font;
      font-size: 20px;
      color: $ORANGE-10;
      margin-right: 16px;
    }
  }

  &__certification-wrapper {
    margin-bottom: 32px;
  }

  &__certification-header {
    font: 300 rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
    letter-spacing: -0.2px;
    position: relative;
    padding-left: 33px;

    &::before {
      content: '\e927';
      @include tv-tfv-font;
      color: $ORANGE-10;
      font-size: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__certification-data {
    font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
    letter-spacing: 0.3px;
  }

  &__submit-wrapper {
    justify-content: center;
  }

  &__submit-btn {
    margin-top: 50px;
    &.tv-buttons__element {
      font-weight: 300;
      padding: 13px 48px;
      line-height: 23px;
    }
  }
}
