@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

@import './media-query.scss';

.tv-right-status {
  padding: 0 15px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $WHITE_COLOR !important;

  &:focus-visible {
    border: 2px solid green;
    border-radius: 4px;
  }
  .mobile-nav {
    margin-left: 25px;
  }
  .navbar-expand {
    margin-left: 10px;
    .navbar-nav {
      justify-content: flex-end;
      margin-right: 10px;
    }
  }

  .nav-item {
    margin: 3px 8px;
    padding: 5px;
    color: $COLOR_NAVIGATION_ITEM;
    font-family: $PRIMARY_FONT;
    font-weight: 300;
    font-size: 16px;

    &:hover {
      color: $COLOR_NAVIGATION_ACTIVE_ITEM;
      text-decoration: underline;
      cursor: pointer;
    }

    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 4px;
    }
  }

  .nav-active {
    color: $COLOR_NAVIGATION_ACTIVE_ITEM;
    font-weight: 600;
    text-decoration: underline;
  }

  .tv-left-pannel__logo {
    @supports (display: grid) {
      padding-top: unset !important;
    }
    @supports (display: -ms-grid) {
      padding-top: 10px;
    }
  }

  .menu-button {
    display: none;
  }

  .tv-bookDirect-button-nav__wrapper {
    .booking-button {
      padding: 14px 14px;
      width: 120px;
    }
  }
  .booking-button {
    margin-left: 0;
    margin-bottom: 0;
    line-height: 12px;
    font-family: $PRIMARY_FONT;
    font-size: 14px;
    background: $BLUE-10;
    border: none;
    outline: none;
    color: white;
    max-height: 48px;
    padding: 14px 24px;
    border-radius: 4px;
    width: 130px;
    text-wrap: nowrap;

    i.fa {
      -webkit-text-stroke: 1px $BLUE-10;
      margin-right: 13px;
    }

    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 4px;
      outline-offset: 2px;
    }
  }

  .show-direct-book {
    text-wrap: nowrap;
    margin-right: 8px;
  }

  .icon-search {
    color: $BLACK_COLOR;
  }

  a:hover {
    text-decoration: none;
  }

  .tv-filter-list {
    &__element {
      & .dropdown-button {
        &:focus-visible {
          @media screen and (min-width: 768px) {
            position: relative;
            outline: 2px solid $OUTLINE_COLOR;
            border-radius: 50%;
            outline-offset: 2px;
          }
          @media screen and (max-width: 768px) {
            &::after {
              content: '';
              position: absolute;
              top: -7%;
              left: -32%;
              border: 1px solid $OUTLINE_COLOR;
              border-radius: 22px;
              width: 114%;
              height: 108%;
            }
          }
        }
      }
    }
  }
}
.button-style {
  margin-top: 15px;
  margin-left: 10pxs;
}
.tv-bookDirect-button-nav {
  &__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .show-direct-book {
      text-wrap: nowrap;
    }
  }
  border-radius: 40px;
  font-size: 14px;
  padding: 8px 22px;
  height: auto;
  color: $BLUE-10;
}

.top-arrow {
  width: 30px;
  height: 30px;
  color: $ORANGE-10;
  margin-right: 15px;
}
