@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css'; // theme css file

.tv_translation {
  &__wrapper{
    .tv-modal__container {
      @media screen and (max-width: 1100px) {
        width: 100%;
        min-height: -webkit-fill-available;
      }
      .tv-modal__container__body {
        @media screen and (max-width: 1100px) {
          width: 100%;
          max-height: calc(100vh - 120px);
        }
      }
    }
  }
  &__main-panel {
    display: flex;
    @media (min-width: 1100px) {
      .col-sm-12 {
        flex: 0 0 75%;
        max-width: 75%;
      }
      .tv-display-mobile-hide.tabs.col-sm-12 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
    .tv-interperet-now__location-wrapper {
      padding: 11px 27px 30px 27px;
    }

    .tv-toggle-button--phone {
      margin-right: 10px;
    }
    .tv-search-box {
      input {
        margin: 0px 0px 8px 0px;
        width: 100%;
        background-color: #f1f3f4;
      }
      i {
        top: 14px;
        left: 14px;
      }
      .search-box-focus {
        outline: 2px solid $OUTLINE_COLOR;
      }
    }
  }
  &__list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &-title {
      font: 500 24px/35px $PRIMARY_FONT;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      &.tv-display-mobile-show {
        display: block !important;
      }
      &.tv-display-mobile-hide {
        display: none;
      }
    }
  }
  &__add-button {
    font-family: $PRIMARY_FONT;
    font-size: 16px;
    background: $BLUE-10;
    border: none;
    outline: none;
    color: white;
    max-height: 48px;
    padding: 14px 24px;
    margin-left: auto;
    border-radius: 4px;
    i.fa {
      -webkit-text-stroke: 1px $BLUE-10;
      margin-right: 13px;
    }
    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 4px;
      outline-offset: 2px;
    }
  }
  &__detail-container {
    @media screen and (min-width: 768px) {
      top: 79px;
      min-height: 600px;
      padding-top: 0;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      position: fixed !important;
      top: 0 !important;
      left: 0;
      height: calc(100vh);
      z-index: 50;
      min-height: unset;
      overflow: hidden;
      max-width: 100%;
      padding-inline: 0;
    }
  }
  &__detail-panel {
    font-family: $PRIMARY_FONT;
    transform: translateX(-100%);
    animation: slide-in 0.5s forwards;
    left: 0;
    top: 0;
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.04);
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    background-color: #fbfbfb;
    padding-inline: 5px;
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      &-title {
        font: 500 18px/20px $PRIMARY_FONT;
      }
      &-open {
      }

      .tv-buttons__type {
        &:focus-visible {
          outline: 2px solid $OUTLINE_COLOR;
          outline-offset: 8px;
          border-radius: 1px;
        }
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      padding: 15px;
      &-item {
        display: flex;
        align-items: center;
        padding-block: 8px;
        font: 500 13px/20px $PRIMARY_FONT;
        img {
          width: 12px;
          height: 12px;
          margin-right: 15px;
        }
        i {
          margin-right: 12px;
          color: $FONT_LIGHT_GRAY_COLOR;
          font-size: 12px;
        }
      }
      .tv-single-assign-form__file-container {
        li span {
          font-size: 12px;
          &:focus-visible {
            outline: 2px solid $OUTLINE_COLOR;
            outline-offset: 6px;
            border-radius: 1px;
          }
        }
      }
    }
  }
  &__attachement {
    display: flex;
    flex-direction: column;

    &__file-path {
      color: $BLUE-10;
      text-decoration: underline;
      cursor: pointer;
    }
    &__text {
      margin-top: 5px;
      color: #74737b;
    }
  }
  &_list-container {
    .tv-workassignment__list-item {
      &:focus-visible {
        outline: 2px solid $OUTLINE_COLOR;
        border-radius: 2px;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        height: 200px;
        border-radius: 4px;
        position: relative;
        border: 1px solid $BORDER_COLOR;
        margin: 16px 0 !important;
        padding: 16px;

        .tv-workassignment__detail-status {
          margin-top: 17px;
          font: 500 16px/20px $PRIMARY_FONT;
        }

        &.selected .tv-workassignment__detail-status {
          color: #fff !important;
        }
        .tv-display-mobile-hide {
          display: none !important;
        }

        .tv-display-mobile-show {
          display: block !important;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    &__filter-drawer {
      position: fixed !important;
      top: 0 !important;
      left: 0;
      height: calc(100vh);
      z-index: 50;
      min-height: unset;
      overflow-y: scroll;
      overflow-x: hidden;
      max-width: 100%;

      .tk-assigment-list-filter-footer {
        position: absolute;
      }
    }
  }
}

.tv-search-order-bar {
  &__loader {
    position: absolute;
    color: #6478e6;
    top: 0;
    right: 0;
    display: flex;
    padding: 18px 10px;
  }
}

.tv-translation-header {
  text-align: left;

  &__list {
    &-item {
      cursor: pointer;
      margin: 0;
      padding: 8px;
      height: 47px;
      font: 400 12px/15px $PRIMARY_FONT;
      z-index: 10;
      border-radius: 0;
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: center;

      &-header {
        font: 400 12px/15px $PRIMARY_FONT;
        border-bottom: 1px solid $BORDER_COLOR;
        padding: 8px;
        margin: 0 0 16px 0;
        margin-bottom: 16px !important;
        display: flex;
        height: 47px;
        justify-content: space-around;
        width: 100%;
        align-items: center;
        z-index: 10;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        display: none !important;
      }
    }
  }
  &__item {
    &-text {
      color: $FONT_LIGHT_GRAY_COLOR;
      font: 12px/16px $PRIMARY_FONT;
    }
  }
}

.tv-translation {
  &__list-item {
    cursor: pointer;
    margin: 0;
    padding: 8px;
    height: 47px;
    font: 400 12px/15px $PRIMARY_FONT;
    z-index: 8;
    border-radius: 0;
    background: #f1f3f4;
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
  }

  &__item-text {
    font: 500 13px/18px $PRIMARY_FONT;
    text-wrap: nowrap;
    &.text-contact-person {
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-status {
      padding: 6px 8px;
      width: fit-content !important;
      border-radius: 4px;
      color: #ffffff !important;
      &-available {
        background-color: $COLOR_STATUS_AVAILABLE;
      }
      &-accepted {
        background-color: $COLOR_STATUS_ACCEPTED;
      }
      &-fullfilled {
        background-color: $COLOR_STATUS_FULLILLED;
      }
      &-cancelled {
        background-color: $COLOR_STATUS_CANCELLED;
      }
      &-rejected {
        background-color: $COLOR_STATUS_REJECTED;
      }
      &-late-cancelled {
        background-color: $COLOR_STATUS_LATE_CANCELLED;
      }
      &-onGoing {
        background-color: $COLOR_STATUS_ONGOING;
      }
    }
  }

  &__empty-text {
    display: flex;
    justify-content: center;
    font: 400 14px/15px $PRIMARY_FONT;
    padding: 8px;
    color: $FONT_LIGHT_GRAY_COLOR;
  }
}

.tk-close-btn {
  margin-right: 2px;
}

.tv-translation__list__mobile-fixed-bottom {
  position: fixed;
  background-color: #fff;
  text-align: center;
  display: flex;
  left: 0;
  bottom: 0;
  z-index: 20;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-inline: 12px;
  .tv_translation__add-button {
    font-size: 14px;
    @media screen and (max-width: 768px) {
      width: 50%;
      margin-left: 5px;
    }
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    .tv_translation__add-button {
      width: 50%;
      margin-left: 5px;
    }
  }
}
