.tv-left-pannel {
  &__wrapper {
    flex: 1;
    max-width: $pannelLeftWidth;
    background-color: $WHITE_COLOR;
    box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5);
    z-index: 10; // For left pannel higher than right pannel so box-shadow can work
  }

  &__logo {
    font-family: $PRIMARY_FONT;
    letter-spacing: -1px;

    display: flex;
    justify-content: center;
    cursor: pointer;

    a {
      text-decoration: none;
    }

    img {
      width: 190px;
      height: 40px;
    }

    a:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      outline-offset: 5px;
      border-radius: 4px;
    }
  }

  &__sidebar {
    a {
      text-decoration: none;
    }

    .tv-icon-fixed-width {
      display: inline-block;
      width: 24px;
      text-align: center;
    }
  }

  &__sidebar-item {
    display: flex;
    padding: 20px 10px 20px 20px;
    cursor: pointer;
    color: $NAV_BAR_TEXT_COLOR;
    font: 0.875rem/1.5rem $SECONDARY_FONT;
    border-left: 2px solid transparent;

    &:hover,
    &.active {
      background-color: $NAV_BAR_HOVER_COLOR;
      border-left: 2px solid $BLUE-10;
      font-weight: bold;
      color: $BLUE-10;

      i:before {
        color: $BLUE-10;
      }
    }
  }

  &__header-menu {
    display: flex;
    cursor: pointer;
    color: $NAV_BAR_TEXT_COLOR;
    font: 1rem/1.5rem $SECONDARY_FONT;
    border-left: 2px solid transparent;
    & .k-state-selected {
      font-weight: bold;
      & .icon-schedule:before {
        color: $BLUE-10 !important;
      }
    }
    &:hover,
    &.active {
      background-color: $NAV_BAR_HOVER_COLOR;
      border-left: 2px solid $BLUE-10;
      font-weight: bold;
      color: $BLUE-10;
      .icon-schedule:before {
        color: $BLUE-10;
      }
    }
  }

  &__header-menu {
    display: flex;
    cursor: pointer;
    color: $NAV_BAR_TEXT_COLOR;
    font: 1rem/1.5rem $SECONDARY_FONT;
    border-left: 2px solid transparent;

    & .k-state-selected {
      font-weight: bold;

      & .icon-schedule:before {
        color: $BLUE-10 !important;
      }
    }

    &:hover,
    &.active {
      background-color: $NAV_BAR_HOVER_COLOR;
      border-left: 2px solid $BLUE-10;
      font-weight: bold;
      color: $BLUE-10;

      .icon-schedule:before {
        color: $BLUE-10;
      }
    }
  }

  &__sidebar-icon {
    font-size: 21px;
    margin-right: 10px;
  }
}

.tv-right-pannel-wrapper {
  flex: 4.76;
  min-height: 100vh;
  position: relative;
  background-color: $WHITE_COLOR;

  .tv-right-status {
    width: 100%;
    height: 72px;
    background-color: #d2d5db;

    .tv-avatar {
      display: inline;
      position: relative;
      cursor: pointer;

      &__img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: cover;
      }

      // end tv-avatar

      .tv-notification {
        display: inline;
        margin-left: 30px;
        position: relative;
        cursor: pointer;

        &__icon {
          vertical-align: middle;
          font-size: 1.4rem;
          color: #c5c5c5;
        }

        &__circle {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: $BLUE-10;
          display: inline-block;
          position: absolute;
          top: -2px;
          left: 10px;
        }
      }

      // end tv-notification

      .tv-search {
        margin-left: auto;

        .form-inline {
          display: flex;
          align-items: center;

          i {
            margin-right: 15px;
            font-size: 20px;
            cursor: pointer;
          }

          input {
            width: 16rem;
            border: none;
            font-family: 'PT Serif';
            font-size: 1rem;
            color: $NAV_BAR_TEXT_COLOR;
          }
        }
      }

      // end .tv-search
    }

    // end user-info
  }

  // end right-status

  .tv-right-content {
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;

    // max-width: 1130px;
    margin-left: auto;
    margin-right: auto;
  }

  // end tv-right-content
}

// end right-pannel-wrapper

html,
body,
#root,
.tv-container,
.tv-right-pannel-wrapper {
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    // width: 100vw;
    // max-width: 100vw;
    // overflow-x: hidden;
  }
}

// .tv-right-status{
//   display: none !important;
// }

.tv-right-pannel-wrapper .tv-right-status {
  // flex-wrap: wrap;
}
