@import '../../../Share/styles/variables.scss';

.rate-container {
  font: 500 16px/20px $PRIMARY_FONT;
  padding: 16px 16px 16px 0px;
  height: auto;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  @supports (-webkit-background-clip: text) {
    & .stars {
      --percent: var(--rating);
      line-height: 25px;
      -webkit-text-stroke: 1.5px #ff9b4e;
      display: inline-block;
      font-family: Times; // make sure ★ appears correctly
      &::before {
        content: '★★★★★';
        font-size: 25px;
        letter-spacing: 3px;
        background: linear-gradient(
          90deg,
          #ff9b4e var(--percent),
          #fff var(--percent)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  & .stars {
    color: #ff9b4e;
    font-family: Times; // make sure ★ appears correctly
    font-size: 25px;
    letter-spacing: 3px;
  }
  .custom-control-label {
    display: flex;
  }
}

.rate-title {
  font: 500 16px/20px $PRIMARY_FONT;
  margin-bottom: 15px;
}

.rate-star {
  color: transparent;
  text-shadow: 0 0 #ff9b4e;
  font-size: 16px;
}
.rate-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.rate-title-sub {
  font: 400 14px $PRIMARY_FONT;
  line-height: 18px;
  color: #74737b;

  cursor: pointer;

  &:focus-visible {
    outline: 2px solid $OUTLINE_COLOR;
    outline-offset: 4px;
    height: min-content;
    border-radius: 2px;
  }
}

.rate-checkbox-lable {
}
