@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';
@import '../../../Share/styles/fonts.scss';

.timesheet {
  padding: 0 32px 24px 32px;
  width: 100%;
  background-color: $WHITE_COLOR;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }

  &__modal {
    .tv-modal__holder {
      // max-width: initial;
      // padding: 0 15px !important;
      min-width: 672px;
      letter-spacing: 0.1px;
      & h6 {
        letter-spacing: 0.1px;
      }
      .timesheet__travel_time:nth-child(2) {
        margin-left: 32px;
      }

      .timesheet {
        &-button {
          &_cancel,
          &_submit {
            &:focus-visible {
              outline: 2px solid $OUTLINE_COLOR;
              outline-offset: 2px;
            }
          }
        }
      }
    }
  }

  &-button {
    &_cancel {
      border: 1px solid $BORDER_COLOR;
      border-radius: 4px;
      width: 100%;
      height: 48px;
      font: 400 16px/20px $PRIMARY_FONT;
      padding: 10px;
      background: #fff;
      margin: 20px 0 2px;
    }

    &_submit {
      border: none;
      font: 400 16px/20px $PRIMARY_FONT;
      border-radius: 4px;
      width: 100%;
      height: 48px;
      padding: 10px;
      color: $WHITE_COLOR;
      background-color: $BLUE-10;
      margin: 20px 0 2px;
    }
  }
  &__divider {
    border-top: 1px solid #e0e0e0;
    margin-top: 8px;
  }
  &__attached-file {
    &__container {
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      font-size: 14px;
      margin-top: 16px;
      margin-right: 32px;
      & i {
        cursor: pointer;
      }
    }
    &__amount {
      min-width: 50px;
    }
    &__content {
      display: flex;
    }
    &__name {
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  input:disabled {
    background: $WHITE_COLOR;
  }

  select:disabled {
    color: $DARK_GRAY_COLOR;
  }

  &__header {
    font-family: $PRIMARY_FONT;

    &-title {
      background-color: $ORANGE-10;
      border-radius: 4px 4px 0 0;
      color: $WHITE_COLOR;
      @include font-size(24);
      line-height: rem-calc(35);
      padding: 16px 24px;
    }

    &-content {
      padding: 24px 0;
      font-weight: 300;
      letter-spacing: -0.5px;
      border-bottom: 1px solid $BORDER_COLOR;

      .redirect-checkbox {
        label {
          padding-left: 15px;
          font: 400 16px/20px $PRIMARY_FONT !important;
        }
      }

      .tv-single-assign-form__control {
        height: 48px;
        margin-top: 8px;
        margin-bottom: 16px;
        .k-input {
          padding: 16px 14px;
          font: 400 16px/20px $PRIMARY_FONT !important;
          height: 48px;
          align-content: center;
          display: flex;
        }
      }
      textarea.tv-single-assign-form__control {
        margin-top: 8px;
        height: 70px !important;
      }
      .tv-rate-submit-btn {
        width: 100px;
        font: 400 16px/20px $PRIMARY_FONT !important;
        color: $WHITE_COLOR;
        background-color: $BLUE-10;
        padding: 8px !important;
        height: 40px;
      }

      .rating-label {
        font: 400 16px/20px $PRIMARY_FONT !important;
        margin-bottom: 8px;
        margin-top: 16px;
      }
      button {
        color: #000;
        background: #c4c4c4;
        border: #c4c4c4;
        font-weight: 500;
        font-size: 15px !important;
      }

      &-id {
        color: $BLACK_COLOR;
        font: 400 18px/23px $PRIMARY_FONT;
        margin: 0px 10px 18px 0;
        float: left;
      }

      &-type {
        color: $COMMON_TEXT_COLOR;
        font: 400 14px/18px $PRIMARY_FONT;
        margin: 0;
        text-align: left;
      }

      &-date {
        color: $DARK_GRAY_COLOR;
        font: rem-calc(14) / rem-calc(20) $SECONDARY_FONT;
        letter-spacing: 0.3px;
        margin: 10px 0 0 0;
        text-align: left;

        i {
          margin-right: 15px;
        }
      }
    }

    &-rating {
      margin-top: -3px;
      background: white;
      border-radius: 12px;
      &.rating > label {
        padding-right: 2px !important;
      }
    }
  }

  &__travel_time {
    padding: 16px 0px 0px;
    font-family: $PRIMARY_FONT;
    margin-top: 0px;
    text-align: left;
    border-radius: 4px;
    width: 100%;
    .read-only-data {
      margin: 0;
      flex-direction: column;
      display: flex;
      & > div {
        align-items: center;
        margin-bottom: 16px;
      }
      label {
        font: 300 14px/18px $PRIMARY_FONT !important;
        margin-bottom: 0px !important;
      }
      span {
        font: 300 14px/18px $PRIMARY_FONT !important;
      }
    }
    .tv-form__date-field {
      padding: 11px 8px !important;

      &:after {
        top: 13px !important;
        right: 11px !important;
      }
    }

    &.travel-home {
      background-color: #f4f4f4;
      padding: 20px 32px;
      border: 1px solid $TEXT_INACTIVE_COLOR;
    }

    &__box {
      padding: 10px 0;

      .tv-col-6 {
        padding-top: 20px;
      }

      &__content {
        width: 100%;
        border: 1px solid $TEXT_INACTIVE_COLOR;
        border-radius: 5px;
        padding: 20px;

        &-file-name {
          padding-top: 12px;
          color: $ORANGE-10;
          @include font-size(14);
          line-height: rem-calc(18);
          padding: 10px 0;

          span {
            padding-left: 10px;
          }
        }

        .view-data {
          flex-grow: 1;
        }

        &-remove {
          color: $BLUE-10;
          @include font-size(14);
          display: flex;
          min-width: 90px;
          position: relative;
          cursor: pointer;

          i {
            margin: auto;
            cursor: pointer;
            position: absolute;
            background-color: $BLUE-10;
            width: 16px;
            border-radius: 50%;
            height: 16px;
            top: 50%;
            transform: translate(50%, -50%);

            &::before {
              color: $WHITE_COLOR;
              padding: 4px;
              display: block;
              @include font-size(8);
            }
          }

          .title-close {
            padding-left: 20px;
            margin: auto;
          }
        }
      }
    }

    &-rating-phone {
      margin-left: auto;

      .timesheet__rating-text {
        margin: auto;
        padding-right: 10px;
      }

      .rating {
        border: none;
        display: inline-block;

        & > input {
          display: none;
        }

        & > label {
          padding-right: 2px;

          &:before {
            @include font-size(24);
          }
        }
      }
    }

    &-title {
      font: rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
      letter-spacing: -0.2px;
      font-weight: 300;
      color: $BLACK_COLOR;
      line-height: 2.2;
    }

    &-content {
      margin-top: 0;

      .tv-form__date-field {
        padding: 11px 8px !important;

        &:after {
          top: 13px !important;
          right: 11px !important;
        }
      }

      .tv-single-assign-form__input-arrow:after {
        display: none;
      }

      .button-plus {
        position: relative;
        @include font-size(18);
        line-height: rem-calc(28);
        padding: 11px 28px 11px 40px;

        i {
          position: absolute;
          left: 20px;
          margin-top: 3px;
        }

        &::after {
          content: '+';
          position: absolute;
          right: 10px;
          color: $BLUE-10;
          margin-top: 3px;
          font-size: 20px;
          font-weight: 900;
          line-height: 25px;
        }
      }

      .button-minus {
        position: relative;
        @include font-size(18);
        line-height: rem-calc(28);
        padding: 11px 28px 11px 40px;

        i {
          position: absolute;
          left: 20px;
          margin-top: 3px;
        }

        &::after {
          content: '-';
          position: absolute;
          right: 10px;
          color: $BLUE-10;
          margin-top: 3px;
          font-size: 20px;
          font-weight: 900;
          line-height: 25px;
        }
      }
    }

    &-label {
      @include font-size(14);
      line-height: rem-calc(24);
      color: $NAV_BAR_TEXT_COLOR;
      padding-bottom: 5px;
      margin: 0 0 0 0;
      font-size: 15px;
      font-weight: 400;
      font-family: $PRIMARY_FONT;

      &::before {
        margin-right: 10px;
      }
    }

    &-input {
      height: 52px;
      padding: 16px 20px;
    }

    .timesheet_time {
      input {
        width: 100%;
        height: 40px;
        border: 1px solid #bdbdbd !important;
        padding: 10px;
        color: black;
      }

      .rc-time-picker-input {
        border: 1px solid $TEXT_INACTIVE_COLOR;
      }

      i {
        margin-top: 22px;
      }
    }

    .tv-interperet-now__start-time-wrapper {
      padding-top: 5px;
      margin: 0;
    }

    .tv-row > [class^='tv-col-'] {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &__checkbox {
    @include font-size(14);
    padding-top: 20px;

    .tv-form__checkbox-checkmark {
      background-color: $WHITE_COLOR;
      border: 1px solid $ORANGE-10;
    }
  }

  &__rating {
    min-height: 150px;
    background-color: $DARK_GRAY_COLOR-1;
    text-align: center;
    margin: 48px 0 36px;
    padding: 15px 32px 5px;

    &-text {
      color: $DARK_GRAY_COLOR;
      font-family: $SECONDARY_FONT;
      @include font-size(14);
      letter-spacing: 0.3px;
      line-height: rem-calc(20);
    }
  }

  &__submit {
    padding: 10px 0 40px;
    width: 200px;
    margin: 0 auto;

    button {
      padding: 15px 0;
    }
  }

  .tv-single-assign-form__input-arrow {
    position: relative;
    margin-top: 5px;
  }

  .tv-single-assign-form__textfield {
    padding: 16px 20px;

    &:disabled {
      color: $DARK_GRAY_COLOR;
    }
  }

  .tv-single-assign-form__label {
    margin: 0 0 0 0;
    font-size: 15px;
    font-weight: 400;

    &.icon-calendar {
      & > div {
        display: inline-block;
        padding-left: 10px;
      }
    }

    &.icon-submit-time {
      & > div {
        display: inline-block;
        padding-left: 10px;
      }
    }
  }

  .tv-single-assign-form__input {
    margin-left: 0;
  }

  .tv-col-2 {
    .tv-form__date-field {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .pdt0 {
    padding-top: 0;
  }

  .mgt0 {
    margin-top: 0;
  }

  .read-only {
    user-select: none;
    cursor: not-allowed;
    pointer-events: none;

    .icon-arrow-expand {
      display: none;
    }
  }

  &__signature {
    span {
      display: block;
    }

    img {
      max-height: 150px;
    }
  }
}

.tv-single-assign-form__control {
  color: $NAV_BAR_TEXT_COLOR;
}

.timesheet_modal {
  .tv-modal__holder {
    text-align: center;
    padding: 20px 90px 30px;

    .image-upload {
      & > input {
        display: none;
      }

      .icon-upload-to-cloud {
        &::before {
          color: $ORANGE-10;
          @include font-size(22);
        }
      }

      &__title {
        @include font-size(14);
        line-height: rem-calc(24);
        color: $ORANGE-10;
        margin: 5px;
      }
    }

    .image-preview {
      width: 120px;
      border: 1px solid #979797;
      border-radius: 2px;
      margin: 20px auto 0;
      position: relative;

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }

      i {
        &.icon-close {
          cursor: pointer;
          position: absolute;
          top: -12px;
          right: -12px;
          background-color: $BLUE-10;
          width: 24px;
          border-radius: 50%;
          height: 24px;

          &::before {
            color: white;
            @include font-size(12);
          }
        }
      }
    }

    .icon-upload-to-cloud {
      cursor: pointer;
      border: 1px solid $ORANGE-10;
      border-radius: 50%;
      display: inline-block;
      padding-top: 5px;
      margin-top: 20px;
      width: 40px;
      height: 40px;

      [class*='path']::before {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'tfv-icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include font-size(24);
      }
    }

    .path1::before {
      color: $ORANGE-10;
    }
  }

  &__header {
    color: $NAV_BAR_TEXT_COLOR;
    font: rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
    font-weight: 300;
    letter-spacing: -0.2px;
  }
}

.input-group {
  min-width: 275px;
  padding: 28px 0 24px;

  &.view-data {
    padding: 0;
    min-width: 0;
  }

  &__label {
    margin-right: -1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 16px;
    margin-bottom: 0;
    font-size: 1rem;
    @include font-size(14);
    line-height: rem-calc(24);
    color: $NAV_BAR_TEXT_COLOR;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #e8e8e8;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__value {
    min-width: 100px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    padding: 8px 16px;
    color: $TEXT_INACTIVE_COLOR;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__textfield {
    border: 1px solid #e8e8e8;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    padding: 8px 16px;
    color: $BLUE-10;
  }
}
@media (max-width: 768px) {
  .timesheet__modal .tv-modal__holder {
    width: calc(100vw);
    min-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    margin-top: 0;
    top: 0;
    border-radius: 0;
    .timesheet__travel_time:nth-child(2) {
      margin-left: 0px;
    }
    button.tv-rate-submit-btn {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;
    }
    .detailed-rating.timesheet__header-rating {
      width: 100%;

      & .rating {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-bottom: 20px;
        & > label:before {
          font-size: 33px !important;
        }
      }
    }
    .footer-wrapper {
      justify-content: space-between;
      align-items: center;
      width: 100%;
      & > .feedback-create-deviation,
      .tv-rate-submit-btn {
        margin: 0px !important;
      }
    }
  }
}
