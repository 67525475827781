@import '../../styles/variables.scss';

@media (max-width: 768px) {
  .other-month {
    display: none !important;
  }

  .tk-monthly-calendar {
    &__wrapper {
    }

    &__main {
    }

    &__item {
      &__header {
        padding: 0 !important;
      }

      &-text {
        display: unset;
        font: 11px/16px $PRIMARY_FONT;
        text-transform: uppercase;
      }
      &-create-order {
        padding: 8px;
        margin: 8px 2px;
        text-align: left;
      }
      &-count {
        padding: 16px 8px;
      }
      &-notification {
        display: flex;
        align-items: center;
        padding-right: 10px;
      }
      &-number {
        color: $FONT_LIGHT_GRAY_COLOR;
        font: 11px/16px $PRIMARY_FONT;
        font-weight: 400;
        text-align: center;
        background-color: $WHITE-COLOR;
        text-align: left;
        padding: 0 0 8px 0;
        text-transform: uppercase;

        .today & {
          position: relative;
          color: $WHITE-COLOR;
          padding: 4px 2px;
          background-color: $ORANGE-10;
          z-index: 0;
          &::before {
            content: none;
          }
        }
      }

      &-empty {
        display: flex;
        opacity: 1;
        color: $COMMON_TEXT_COLOR;
        text-align: left;
        padding: 16px;
        margin: 8px 2px;
      }

      &-no_event {
        background-color: $BACKGROUND_COLOR;
        text-align: center;
      }
    }

    &__table {
      margin: 7px;

      &-weekdays {
        display: none !important;
      }

      &-item {
        padding: 0px !important;
        height: auto;
        min-width: 40px;
        border: none !important;
        &.weekend-background {
          margin-bottom: 8px;
        }
      }
      @supports (display: grid) {
        &-wrapper {
          grid-template-columns: 1fr;
          border-radius: 15px;
          border: none !important;
          height: 650px;
          box-shadow: none;
          td {
            min-height: 85px;
            height: auto;
          }
          tr,
          tbody {
            grid-template-columns: 1fr;
            border: none !important;
          }
          tbody {
            margin-bottom: 60px;
          }
          tr {
            grid-column: 1 / 2;
          }
          tr:last-child {
            margin-bottom: 80px !important;
          }
        }
      }
      &-wrapper {
        th,
        td {
          display: table-row;
        }
      }
    }
  }
}
