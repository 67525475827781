.container {
  // display: flex;
  // flex-direction: row !important;
  width: 100%;
  padding: 0%;

  input {
    padding: 14px 20px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    width: 100%;
    outline: none;
    color: #313131;
  }

  @media (min-width: 576px) {
    max-width: 100%;
  }
}

.tag {
    display: flex;
    align-items: center;
    margin: 7px 0;
    margin-right: 5px;
    padding: 0px 5px;
    border-radius: 5px;
    background-color: #757575;
    white-space: nowrap;
    color: white;
    font-size: 13px;
    width: fit-content;

    button {
      display: flex;
      padding: 6px;
      border: none;
      background-color: unset;
      cursor: pointer;
      color: white;
    }
}