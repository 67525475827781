@import '../../styles/variables.scss';

$AVAILABLE_COLOR: #6566b9;
$BLUE-10: #e00909;
.tv-dailyplanner {
  &_badge {
    &-availability {
      border-radius: 5px;
      position: relative;

      text-transform: uppercase;
      font: 0.675rem/0.675rem $PRIMARY_FONT, sans-serif;
      margin: 5px;
      min-width: 60px;
      max-width: 100px;
      letter-spacing: 1px;
      line-height: 0.8rem;
      cursor: pointer;
      background-color: #fff;
      padding: 8px;
      box-shadow: 0px 2px 5px 2px rgba(117, 118, 124, 0.29);
      transition: background 0.2s ease-out 0s,
        box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;

      &.unavailable {
        border-left: 5px solid $BLUE-10;
        letter-spacing: 8px;
        cursor: pointer;
      }

      &.available {
        letter-spacing: 8px;
        cursor: pointer;
        border-left: 5px solid $AVAILABLE_COLOR;
      }
      &.disabled {
        cursor: not-allowed;

        background-color: #d4d4d4;
      }
      &.info {
        font-size: 0.575rem;
      }
      &-delete {
        position: absolute;
        cursor: pointer;
        font-family: 'tfv-icon' !important;
        top: -5px;
        right: -8px;
        font-size: 0.475rem;
        letter-spacing: normal;
        &::before {
          content: '\e926';
          color: #fff;
          padding: 4px;
          border-radius: 50%;
          background-color: $BLUE-10;
          border: 1px solid #fff;
          box-shadow: 4px 0px 4px 0px #9e9e9e;
        }
      }
    }
  }
}
