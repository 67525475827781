@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

@media screen and (max-width: 1024px) {
  .tv-settings {
    &__wrapper {
      margin: 0px 0px !important;
      font-family: $PRIMARY_FONT;
    }
    &__panel-wrapper {
      .tv-settings-term-tabs {
        ul.react-tabs__tab-list {
          padding: 0px 0 16px 0 !important;
          margin-bottom: unset !important;
          border-radius: 4px;
          li {
            margin-right: unset !important;
            font-family: $PRIMARY_FONT !important;
            font-style: normal;
            font-weight: normal;
            height: 48px;
            border: 1px solid $BORDER_COLOR !important;
            background-color: #fff !important;
            width: 100%;
            padding: 0px 0px !important;
            font-size: 16px !important;
            line-height: 20px !important;
            text-decoration: none !important;
            display: flex !important;
            color: #74737b;
            align-items: center;
            text-align: center;
            .react-tabs__tab {
              &--selected {
                border: 1px solid $BLUE-10 !important;
                button {
                  color: $COMMON_TEXT_COLOR !important;
                }
              }
              button {
                color: #74737b;
                width: 100% !important;
                background-color: #fff;
                height: 48px !important;
                border: 1px solid $BORDER_COLOR;
              }
            }
            &:first-child button {
              border-right: none !important;
            }
            &:last-child button {
              border-left: none !important;
            }
          }
        }

        &_1 {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          // padding: 7px 17px 5px;
        }

        &_2 {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;

          // padding: 7px 17px 5px;
        }
      }
    }

    &-manageuser-wrapper {
      height: 100vh;
      overflow-y: scroll;
    }

    &__tabs-wrapper {
      display: block !important;

      // Override react-tab CSS
      .react-tabs {
        &__tab-list {
          margin-right: unset !important;
        }

        &__tab-panel {
          margin-left: unset !important;

          &--selected {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    &__datapolicy {
      max-width: 900px;
      font-size: 14px;
      line-height: 18px;
      p {
        font-size: 14px;
        line-height: 18px;
      }
      li {
        width: 100%;
        padding: 0px 0px !important;
        margin-left: 16px;
      }
      &_panel {
        margin-left: unset !important;
      }
    }
  }
  .tv-settings__tabs-wrapper .react-tabs__tab {
    border-radius: 4px;
    border: 1px solid $BORDER_COLOR !important;
    display: flex;
    text-align: center;
    padding: 15px !important;
    margin: 0px 0px 8px 0px !important;
    font: 500 16px/20px $PRIMARY_FONT, sans-serif !important;
  }
  .tv-settings__tabs-wrapper .react-tabs__tab--selected {
    border: 1px solid $BLUE-10 !important;
    border-radius: 4px !important;
  }
}
