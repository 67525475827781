@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-notification {
  &__page {
    width: 80%;
    margin-left: auto;
    &__title {
      font-weight: 500;
      font-size: 24px;
      color: #252525;
      margin-bottom: 0;
      font-family: $PRIMARY_FONT;
    }
  }

  &__container {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    top: 45px;
    right: 0;
    // right: 5%;
    min-width: 300px;
    z-index: 100;
    text-align: left;
    height: auto;
    width: 321px;
    font-family: $PRIMARY_FONT;

    &-fluid {
      width: 100%;
    }
  }
  &__list {
    max-height: 60vh;
    overflow-y: scroll;
    z-index: 10;
    @include scroll-bar();
    // @include tv-scroll-bar();
    .tv-checkbox .custom-control-input ~ .custom-control-label:before {
      background: black;
    }
  }
  &__date {
    font-family: $PRIMARY_FONT;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #7c7c7c;
    padding: 24px 21px 8px;
  }
  &__button {
    width: 100%;
    display: flex;
    padding: 14px;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: $BLUE-10;

    &:focus-visible {
      outline: 2px solid $OUTLINE_COLOR;
      border-radius: 4px;
    }
  }
  @media (max-width: 768px) {
    &__container {
      overflow-y: scroll;
    }
    &_header {
      margin-right: 24px;
    }
    &__page {
      width: 100%;

      &__title {
        display: none;
      }

      .tv-assignment-type {
        &__container {
          align-items: center;
        }
        &-article-name {
          font-weight: 500;
          line-height: 20px;
          font-size: 14px;
          color: #252525;
        }
        &-article-icon {
          margin-right: 11px !important;
          color: #74737b;
          font-size: 20px;
          &:before {
            font-size: 13px;
          }
          &:before {
            font-size: 20px;
          }
          // @include status-colors();
        }
      }
      .tv-notification-request__container {
        padding: 0;
        .tv-assignment-item-mobile__container {
          margin-bottom: 0;
        }
      }
    }
    &__container {
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 72px;
      overflow-y: auto;
    }
    &__list {
      max-height: none;
      overflow: none;
      @include scroll-bar();
    }
    // &__button {
    // display: none;
    // padding-top: 7px;
    // }
  }
}
