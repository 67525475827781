@import "../../../Share/styles/variables.scss";
@import "../../../Share/styles/mixin.scss";

.thanks-wrapper {
  width: 65%;
  height: 100%;
  border-radius: 5px;
  background-color: $WHITE_COLOR;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  margin-top: 32px;
  padding: 40px 80px 40px;
  text-align: center;
  color: $NAV_BAR_TEXT_COLOR;
  display: flex;
  flex-direction: column;
  font: rem-calc(16)/rem-calc(24) $SECONDARY_FONT;

  &__header {
    height: 200px;
    width: 240px;
    background-image: url("/images/thank-you.svg");
    background-size: cover;
    background-position-y: center;
    margin: 0 auto;
  }

  &__content {
    padding-top: 20px;

    .booking {
      color: #313131;
      font: rem-calc(32)/rem-calc(40) $PRIMARY_FONT;
      font-weight: 300;
      letter-spacing: -0.5px;
    }
  }

  &__footer {
    margin-top: 30px;

    button {
      padding: 15px 100px;
    }
  }

  .tv-youravailability__list {
    padding-left: 20px;
    padding-top: 20px;
    margin-top: 30px;
    justify-content: center;
    @include scroll-bar($max-heigh: 340px);

    .tv-youravailability__list-item {
      box-shadow: 0 1px 16px 0 rgba(0,0,0,.15);
      margin-right: 10px;
      margin-bottom: 10px;

      .tv-youravailability__container {
        padding: 8px;
      }

      .tv-youravailability__item-text-datetime {
        min-height: unset;
      }

      .tv-workassignment__info-wrapper {
        text-align: left;
      }

      .tv-workassignment__info-wrapper {
        margin: 0;
        padding: 0 0 0 16px;
      }

      .tv-youravailability__item-hr {
        margin: 0 16px;
      }
    }
  }
}